import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customerForgotPassword } from "redux/actions/user";
import { useForm } from "react-hook-form";
import "./forgot-password-page.scss";
import Breadcrumb from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { getLocalStorageValue } from "config/helper";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const [isBackToLoginButtonShow, setBackToLoginButtonShow] = useState(false);

  const { loginError, customerForgotPasswordData } = useSelector(
    ({ user }) => user
  );
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  // Changed label from PIN to Password -1630 - 07/02/2023 - Madhu
  const { is_numeric_password } = headerSectionData;
  const passwordOrPin = is_numeric_password === "1" ? "Pin" : "Password";

  const { register, handleSubmit, errors } = useForm();

  useEffect(() => {
    if (customerForgotPasswordData?.status === "success") {
      setBackToLoginButtonShow(true);
    }
  }, [customerForgotPasswordData]);

  const submitForgotPasswordEmail = (data) => {
    const { email } = data;
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      email_id: email,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(customerForgotPassword(body));
  };

  const breadcrumbList = [{ lable: "Forgot Password", link: "" }];

  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />

      <div className="forgot-password-page-wrapper">
        <div className="container">
          <div className="auth-grid-alignment">
            <div className="auth-grid">
              <div className="auth-grid-items">
                <div className="logo-center-wrapper">
                  <img
                    src={headerSectionData.rsa_client_logo}
                    alt={headerSectionData.image_alt_text}
                  />
                </div>
                <h1>
                  Great to <br />
                  <span>see you back</span>
                </h1>
              </div>
              <div className="auth-grid-items">
                <h2>Reset {passwordOrPin}</h2>
                <p>
                  Enter your email and we’ll send a link on your email to reset
                  your {passwordOrPin}.
                </p>
                <form
                  name="login"
                  onSubmit={handleSubmit(submitForgotPasswordEmail)}
                >
                  <div className="form-control">
                    <label>Email*</label>
                    <input
                      name="email"
                      id="email"
                      placeholder="Enter your email"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      })}
                    />
                    {errors.email && errors.email.type === "required" && (
                      <span>Please enter email.</span>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <span>
                        The email address entered is invalid, please check the
                        formatting (e.g. email@domain.com)
                      </span>
                    )}
                  </div>
                  {loginError
                    ? // <span className="forgot-password-error">{loginError}</span>
                      ""
                    : ""}
                  <span
                    className={
                      customerForgotPasswordData?.status === "error"
                        ? "forgot-password-error"
                        : "link-send-success-message"
                    }
                  >
                    {customerForgotPasswordData?.message}
                  </span>

                  <div className="sign-in-button">
                    {isBackToLoginButtonShow ? (
                      <div className="back-to-login-link">
                        <Link to="/login">Back to Sign In</Link>
                      </div>
                    ) : (
                      <button type="submit">Send Link</button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
