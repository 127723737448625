import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GridIcon from "assets/icons/grid.svg";
import SortByIcon from "assets/icons/sort-by.svg";
import FilterIcon from "assets/icons/filter-icon.svg";
import CloseIcon from "assets/icons/white-close.svg";
import Dropdown from "components/Common/Dropdown";
import { updatePageLimit, updateSortByValue } from "redux/actions";
import ListingPageSidebar from "../Sidebar";
import "./actions.scss";
import loader from "assets/images/loader.gif";

const Actions = ({
  pageLimit,
  setPageLimit,
  sortByOption,
  listLimitOption,
  productListByDepartmentTotalCount,
  priceSection,
  departmentTree,
  otherDepartmentTree,
  iftOptionList,
  maxPriceValue,
  minPriceValue,
  isShowReorder,
  handleClickAddAllToCart,
  querySearchKey,
  redirectBaseUrl,
}) => {
  const dispatch = useDispatch();
  const {
    pastOrderCheckedList,
    fromCheckedList,
    sortByValue,
    productListByDepartmentLoading,
    filterOptions,
  } = useSelector(({ product }) => product);
  const { uncompleteProductMessage } = useSelector(
    ({ customlist }) => customlist
  );

  const [isShowMobileFilter, setIsShowMobileFilter] = useState(false);

  const [sortType, setSortType] = useState("");

  useEffect(() => {
    if (!sortByValue) {
      setSortType("");
    }
  }, [sortByValue]); // eslint-disable-line

  const handleChangeOption = (_, value) => {
    setPageLimit(value);
    dispatch(updatePageLimit(value));
  };

  const handleChangeSortFilter = (sortBy, value) => {
    setSortType(value);
    dispatch(updateSortByValue(sortBy));
  };

  
  return (
    <div>
      <div className="listing-action-section">
        <div className="listing-grid-wrapper">
          <div className="found-items">
            {!productListByDepartmentLoading && productListByDepartmentTotalCount > 0  && (
              <p>
                We found{" "}
                <span> {productListByDepartmentTotalCount} products </span> for
                you!
              </p>
            )} 
            {!productListByDepartmentLoading && productListByDepartmentTotalCount == 0  && (
              <p>
                Currently there are no products that match your search criteria. Please try searching all products.
              </p>
            )}
            {productListByDepartmentLoading && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ width: '100px', height: '50px' }} src={loader} />
                <span>Loading, please wait....</span>
              </div>
            )}
            {!productListByDepartmentLoading && (
              <p className="product-count-mobile-screen">
                <span> {productListByDepartmentTotalCount} items </span>
                {isShowReorder &&
                  (!!pastOrderCheckedList.length ||
                    !!fromCheckedList.length) && (
                    <button
                      className="add-all-to-cart-button"
                      onClick={handleClickAddAllToCart}
                    >
                      <span>Add All to Cart</span>
                    </button>
                  )}
                <div
                  className="mobile-filter-button"
                  onClick={() => setIsShowMobileFilter(!isShowMobileFilter)}
                >
                  <button>
                    <img src={FilterIcon} alt="FilterIcon" />
                    <span>Filter</span>
                  </button>
                </div>
              </p>
            )}
          </div>

          <div className="all-action-wrapper">
            {listLimitOption && (
              <Dropdown
                optionList={listLimitOption}
                icon={GridIcon}
                lable="Show"
                value={pageLimit}
                defaultValue={pageLimit}
                onChange={handleChangeOption}
              />
            )}

            {sortByOption && (
              <Dropdown
                optionList={sortByOption}
                icon={SortByIcon}
                value={sortType}
                lable="SortBy"
                onChange={handleChangeSortFilter}
                activeTabId={sortType}
              />
            )}
          </div>
        </div>
        <div className="restricted">
          <p>{uncompleteProductMessage && uncompleteProductMessage}</p>
        </div>
      </div>
      <div
        className={
          isShowMobileFilter
            ? "mobile-filter mobile-filter-show"
            : "mobile-filter mobile-filter-hidden"
        }
      >
        <div className="mobile-filter-header">
          <p>Filter</p>
          <div
            className="filter-close"
            onClick={() => setIsShowMobileFilter(false)}
          >
            <img src={CloseIcon} alt="CloseIcon" />
          </div>
        </div>
        <ListingPageSidebar
          priceSection={priceSection}
          departmentTree={departmentTree}
          otherDepartmentTree={otherDepartmentTree}
          iftOptionList={filterOptions}
          maxPriceValue={maxPriceValue}
          minPriceValue={minPriceValue}
          isMobileSidebar={true}
          isShowReorder={isShowReorder}
          querySearchKey={querySearchKey}
          redirectBaseUrl={redirectBaseUrl}
        />
      </div>
    </div>
  );
};
export default Actions;
