import Breadcrumb from "components/Common/Breadcrumb";
import AccountWrapper from "./AccountWrapper";

const MyAccount = () => {
  const breadcrumbList = [{ lable: "My Account", link: "" }];
  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <AccountWrapper />
    </>
  );
};
export default MyAccount;
