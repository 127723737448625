import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import DepartmentListItem from "./DepartmentListItem";
import {
  updateIftCheckList,
  updatePriceValue,
  updateFromList,
  updatePastOrderList
} from "redux/actions";
import OtherDepartmentListitem from "./OtherDepartmentListitem";

import Slider from "rc-slider";
import "./sidebar.scss";
import "rc-slider/assets/index.css";
import { useNavigate } from "react-router-dom";

const Sidebar = ({
  priceSection,
  minPriceValue,
  maxPriceValue,
  departmentTree,
  otherDepartmentTree,
  iftOptionList,
  isMobileSidebar,
  isShowReorder,
  redirectBaseUrl,
  isCateringDepartment,
  querySearchKey,
}) => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const setServicesValueDebounced = useRef(
    _.debounce((value) => {
      scrollToTop();
      dispatch(updatePriceValue(value));
    }, 1000)
  );

  const [isShowDepartmentTree, setShowDepartmentTree] = useState(true);
  const [isShowOtherDepartmentTree, setShowOtherDepartmentTree] =
    useState(true);
  const [isShowReorderCheckbox, setShowReorderCheckbox] = useState(true);
  const [rangeSliderValue, setRangeSliderValue] = useState([]);

  useEffect(() => {
    setRangeSliderValue([
      +minPriceValue ? +minPriceValue : priceSection && +priceSection.min_price,
      +maxPriceValue ? +maxPriceValue : priceSection && +priceSection.max_price,
    ]);
  }, [minPriceValue, maxPriceValue, priceSection]); //eslint-disable-line

  const handleChangeRangeSlider = (value) => {
    setRangeSliderValue(value);
    setServicesValueDebounced.current(value);
  };

  const { iftCheckedList, fromCheckedList, pastOrderCheckedList,productListByDepartmentLoading } = useSelector(
    ({ product }) => product
  );
  const {
    from_list = [],
    from_list_title = "",
    from_post_orders_title = "",
    from_post_orders_list = [],
  } = useSelector(
    ({ product }) => product.filterOptionList.reorder_section || {}
  );

  const handleIftChange = (selectIft, checked) => {
    if (!isUserLoggedIn && checked && selectIft === "my_favorites") {
      navigate("/list-items");
    }
    else {
      scrollToTop();
      dispatch(updateIftCheckList(selectIft));
    }
  };

  const handleFromListChange = (fromList) => {
    dispatch(updateFromList(fromList));
  };

  const handleFromPastOrderChange = (pastDate) => {
    dispatch(updatePastOrderList(pastDate));
  };

  return (
    <div
      className={`listing-sidebar-sticky ${isMobileSidebar ? "mobile-view" : ""
        }`}

      id="style-5"
    >
      <div className="listing-page-sidebar">
        {isShowReorder ? (
          <div className="sidebar-item-list">
            <div
              className="sidebar-title-alignment re-order-checkbox-wrapper"
              onClick={() => setShowReorderCheckbox(!isShowReorderCheckbox)}
            >
              <span>Re-Order</span>
              <i className="fa-solid fa-angle-down"></i>
            </div>
            <div
              className={
                isShowReorderCheckbox ? "dropdown-show" : " dropdown-hidden "
              }
            >
              <div>
                <span className="reorder-dropdown-subheading">
                  {from_list_title}
                </span>
                {from_list && !!from_list.length
                  ? from_list
                    .filter((item) => item.is_visible === "1")
                    .map((fromList) => {
                      return (
                        <div
                          className="ift-item-with-checkbox"
                          key={fromList.list_id}
                        >
                          <input
                            type="checkbox"
                            id={fromList.list_id}
                            onChange={() =>
                              handleFromListChange(fromList.list_id)
                            }
                            checked={fromCheckedList.includes(
                              fromList.list_id
                            )}
                          />
                          <label htmlFor={fromList.list_id}>
                            <span>{fromList.title} 1</span>
                          </label>
                        </div>
                      );
                    })
                  : "No list found."}
              </div>
              <div>
                <span className="reorder-dropdown-subheading">
                  {from_post_orders_title}
                </span>

                {from_post_orders_list && !!from_post_orders_list.length
                  ? from_post_orders_list
                    .filter((item) => item.is_visible === "1")
                    .map((pastOrderDate) => {
                      return (
                        <div
                          className="ift-item-with-checkbox"
                          key={pastOrderDate.list_id}
                        >
                          <input
                            type="checkbox"
                            id={pastOrderDate.reorder_date}
                            onChange={() =>
                              handleFromPastOrderChange(
                                pastOrderDate.reorder_date
                              )
                            }
                            checked={pastOrderCheckedList.includes(
                              pastOrderDate.reorder_date
                            )}
                          />
                          <label htmlFor={pastOrderDate.reorder_date}>
                            <span>{pastOrderDate.title}</span>
                          </label>
                        </div>
                      );
                    })
                  : "No orders found."}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {iftOptionList && (
          <div className="sidebar-item-list">
            <div className="sidebar-title-alignment">
              <span>Show</span>
            </div>
            <br></br>
            {iftOptionList &&
              iftOptionList.sort(function (a, b) {
                return a.order_by - b.order_by;
              }).map((item) => {
                return (
                  <>
                  {item.is_visible === "1" &&(
                    <div className="ift-item-with-checkbox" key={item.ift_id}>
                      <input
                        type="checkbox"
                        id={item.ift}
                        onChange={(e) => handleIftChange(item.ift, e.target.checked)}
                        checked={iftCheckedList.includes(item.ift)}
                      />
                      <label htmlFor={item.ift} title={(!isUserLoggedIn && item.ift === "my_favorites") ? "Please login to apply the Favorites filter" : ""}>
                        <span>{item.title} {(item.ift !== "my_favorites" && item.count !== "0") ? `(${item.count})` : ""}</span>
                        <span style={{ color: 'red', fontSize: 'x-small', fontStyle: 'italic', verticalAlign: 'bottom' }}>{(!isUserLoggedIn && item.ift === "my_favorites") ? "Login Required" : ""}</span>
                      </label>
                    </div>
                    )}
                  </>
                );
              })}
          </div>
        )}
        {departmentTree && departmentTree.length ? (
          <div className="department-list">
            <div
              className="sidebar-title-alignment"
              onClick={() => setShowDepartmentTree(!isShowDepartmentTree)}
            >
              <span>
                {isCateringDepartment
                  ? departmentTree[0] && departmentTree[0].text
                  : "Departments"}
              </span>
              <i className="fa-solid fa-angle-down"></i>
            </div>
            <div
              className={
                isShowDepartmentTree ? "department-show" : " department-hidden "
              }
              id="style-5"
            >
              <DepartmentListItem
                isShowDepartmentTree={isShowDepartmentTree}
                departmentTree={departmentTree}
                redirectBaseUrl={redirectBaseUrl}
                querySearchKey={querySearchKey}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {otherDepartmentTree && otherDepartmentTree.length ? (
          <div className="other-department-list">
            <div
              className="sidebar-title-alignment"
              onClick={() =>
                setShowOtherDepartmentTree(!isShowOtherDepartmentTree)
              }
            >
              <span>
                {isCateringDepartment ? "More.." : "Other Departments"}
              </span>
              <i className="fa-solid fa-angle-down"></i>
            </div>
            <div
              className={
                isShowOtherDepartmentTree
                  ? "dropdown-show"
                  : " dropdown-hidden "
              }
            >
              <OtherDepartmentListitem
                isShowOtherDepartmentTree={isShowOtherDepartmentTree}
                otherDepartmentTree={otherDepartmentTree}
                redirectBaseUrl={redirectBaseUrl}
              />
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {priceSection && (
        <div className="sidebar-toggle-item">
          <div className="shop-by-price">
            <h2>{priceSection.main_title}</h2>
          </div>
          <div className="range-style">
            <div>
              <Slider
                range
                defaultValue={[priceSection.min_price, priceSection.max_price]}
                min={priceSection.min_price}
                max={priceSection.max_price}
                step={1}
                onChange={handleChangeRangeSlider}
                allowCross={false}
                pushable={1}
                value={rangeSliderValue}
              />
            </div>
          </div>
          <div className="range-details">
            <p>{priceSection.sub_title}</p>
            {maxPriceValue && minPriceValue ? (
              <span>
                ${minPriceValue} - ${maxPriceValue}
              </span>
            ) : (
              <span>
                {priceSection && priceSection.min_price_label} -
                {priceSection && priceSection.max_price_label}
              </span>
            )}
          </div>
        </div>
      )}


    </div>
  );
};

export default Sidebar;
