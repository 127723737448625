import React, { useEffect } from "react";
import SpotlightBanner from "components/SpotlightBanner";
import DepartmentSlider from "./DepartmentSlider";
import OfferSection from "./OfferSection";
import PreviousBuysSection from "./PreviousBuysSection";
import TestimonialsSection from "./TestimonialsSection";
import AddSection from "./AddSection";
import { useDispatch, useSelector } from "react-redux";
import EcomDisabledOfferSection from "./EcomDisabledOfferSection";
import DownloadOurApp from "./DownloadOurApp";
import SingleBanner from "components/SingleBanner";
import Promotion from "./Promotion";
import PromotionSection from "./PromotionSection";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper";
import { getOffersWithProductData } from "redux/actions/homepage";

const HomePage = () => {
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const clientId = getLocalStorageValue("RSAclient-id");
  const memberNumber = getLocalStorageValue("member-number");
  const { isUserLoggedIn } = useSelector(({ user }) => user);

  const { theme_type, is_clear_localstorage } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const isLocalStorage = getLocalStorageValue("is_clear_localstorage");

  useEffect(() => {
    if (is_clear_localstorage !== isLocalStorage && isLocalStorage !== null) {
      localStorage.clear();
      setLocalStorageValue("is_clear_localstorage", is_clear_localstorage);
      window.location.reload();
    } else {
      setLocalStorageValue("is_clear_localstorage", is_clear_localstorage);
    }
  }, [isLocalStorage, is_clear_localstorage]);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getOffersWithProductData(body));
  }, [storeId]); // eslint-disable-line

  const {
    ecom_section: { allow_ecommerce = "" },
  } = useSelector(({ common }) => common.commonDetailsData);

  return (
    <>
      <SpotlightBanner />
      <div className="app-banner">
        <DownloadOurApp />
        <SingleBanner />
      </div>
      <DepartmentSlider />
      <PromotionSection />
      {allow_ecommerce === "1" ? (
        <OfferSection />
      ) : (
        <EcomDisabledOfferSection />
      )}
      <Promotion />
      <AddSection />
      {isUserLoggedIn && <PreviousBuysSection />}

      <TestimonialsSection />
    </>
  );
};

export default HomePage;
