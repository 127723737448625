import React, { useState, useEffect } from "react";
import './checkbox-group.scss'
import { useDispatch } from 'react-redux';
import {
    handleUpdateCateringAndMealCustomOption
} from "redux/actions";

const CateringCheckBoxGroup = ({
    groupData
}) => {
    const dispatch = useDispatch()
    const { values, title, is_required, title: label, is_add_option_price, id: groupId, max_selection } = groupData

    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOptionData, setSelectedOptionData] = useState([]);

    useEffect(() => {

        const defaultSelectedOptionData = values.filter(option => option.is_default === "1")
        const defaultSelectedOption = values.filter(option => option.is_default === "1").map(el => el.value)
        const updatedDefaultOptionList = defaultSelectedOptionData.map(ele => {
            return {
                ...ele,
                is_add_option_price,
                label
            }
        })
        setSelectedOptionData(defaultSelectedOptionData)
        setSelectedOption(defaultSelectedOption)
        defaultSelectedOptionData.length &&
            dispatch(handleUpdateCateringAndMealCustomOption({ [groupId]: [...updatedDefaultOptionList] }))

    }, [values]) // eslint-disable-line



    const selectedOptionIds = selectedOptionData.map(op => op.id)

    const handleChangeOption = (optionValue, option) => {

        const { id, option_id, price, value } = option

        let updatedIftCheckedList = selectedOption;

        if (updatedIftCheckedList.includes(optionValue)) {
            updatedIftCheckedList = updatedIftCheckedList.filter(
                (iftType) => iftType !== optionValue
            );
        } else {
            if (max_selection !== "0" && (updatedIftCheckedList.length <= (+max_selection - 1))) {
                updatedIftCheckedList.push(optionValue);
            }
            if (!+max_selection) {
                updatedIftCheckedList.push(optionValue);
            }
        }

        let updatedOptionList = selectedOptionData;
        if (selectedOptionIds.includes(id)) {
            updatedOptionList = updatedOptionList.filter(
                (iftType) => iftType.id !== id
            );
        } else {
            if (max_selection !== "0" && (updatedOptionList.length <= (+max_selection - 1))) {
                updatedOptionList.push({ id, is_add_option_price, label, option_id, price, value });
            }
            if (!+max_selection) {
                updatedOptionList.push({ id, is_add_option_price, label, option_id, price, value });
            }
        }

        setSelectedOption([...updatedIftCheckedList])
        setSelectedOptionData([...updatedOptionList])
        dispatch(handleUpdateCateringAndMealCustomOption({ [groupId]: [...updatedOptionList] }))
    }

    return (
        <div className="catering-checkbox">
            <p><strong>{title}</strong>{is_required === "1" && <span>*</span>}</p>
            <div className="catering-checkbox-item-list">
                {values && values.length && values.map((option => {
                    return <div className="catering-item-with-checkbox" key={option.id}>
                        <input
                            type="checkbox"
                            id={option.id}
                            onChange={() => handleChangeOption(option.value, option)}
                            checked={selectedOption.includes(option.value)}
                        />
                        <label htmlFor={option.id}>
                            <span>{option.value} {option.price !== "0.00" ? `- $${option.price}` : ""}</span>
                        </label>
                    </div>
                }))}
            </div>
        </div>
    );
};

export default CateringCheckBoxGroup;
