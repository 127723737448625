import {
  FETCH_USER_REGISTER_DETAILS,
  FETCH_USER_REGISTER_DETAILS_SUCCESS,
  FETCH_USER_REGISTER_DETAILS_FAILURE,
  FETCH_USER_LOGIN_DETAILS,
  FETCH_USER_LOGIN_DETAILS_SUCCESS,
  FETCH_USER_LOGIN_DETAILS_FAILURE,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_DETAILS_FAILURE,
  FETCH_CLIENT_DETAILS,
  FETCH_CLIENT_DETAILS_SUCCESS,
  FETCH_CLIENT_DETAILS_FAILURE,
  CUSTOMER_FORGOT_PASSWORD,
  CUSTOMER_FORGOT_PASSWORD_SUCCESS,
  CUSTOMER_FORGOT_PASSWORD_FAILURE,
  DELETE_USER_ACCOUNT,
  DELETE_USER_ACCOUNT_SUCCESS,
  DELETE_USER_ACCOUNT_FAILURE,
  CLEAR_REGISTER_MESSAGE,
  CLEAR_LOGIN_MESSAGE,
  CHANGE_EMAIL_UPDATE,
  CHANGE_EMAIL_UPDATE_SUCCESS,
  CHANGE_EMAIL_UPDATE_FAILURE,
  CLEAR_CHANGE_EMAIL_UPDATE_RESPONSE,
} from "redux/constants/actionTypes";
import axios from "config/axios";
import { getLocalStorageValue, setLocalStorageValue } from "config/helper";
import axiosLib from "axios";

export const fetchUserRegisterDetails = (payload) => {
  return {
    type: FETCH_USER_REGISTER_DETAILS,
    payload: payload,
  };
};

export const fetchUserRegisterSuccess = (payload) => {
  return {
    type: FETCH_USER_REGISTER_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchUserRegisterFailure = (payload) => {
  return {
    type: FETCH_USER_REGISTER_DETAILS_FAILURE,
    payload: payload,
  };
};

export const userRegister = (body) => async (dispatch) => {
  dispatch(fetchUserRegisterDetails());
  axios
    .post("/AogCustomerRegister", body)
    .then((res) => {
      dispatch(fetchUserRegisterSuccess(res.data));
    })
    .catch((error) => {
      dispatch(
        fetchUserRegisterFailure({ error: error.response.data.message })
      );
    });
};

export const fetchUserLoginDetails = (payload) => {
  return {
    type: FETCH_USER_LOGIN_DETAILS,
    payload: payload,
  };
};

export const fetchUserLoginSuccess = (payload) => {
  return {
    type: FETCH_USER_LOGIN_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchUserLoginFailure = (payload) => {
  return {
    type: FETCH_USER_LOGIN_DETAILS_FAILURE,
    payload: payload,
  };
};

export const userLogin = (body, handleLoginSuccess) => async (dispatch) => {
  dispatch(fetchUserLoginDetails());
  axios
    .post("/AogCustomerLogin", body)
    .then((res) => {
      if (res.data.status === "success") {
        setLocalStorageValue("user-token", res.data.data.user_token);
        setLocalStorageValue("member-number", res.data.data.member_number);
        setLocalStorageValue("first-name", res.data.data.first_name);
        setLocalStorageValue("last-name", res.data.data.last_name);
        setLocalStorageValue("mobile-number", res.data.data.mobile_number);
        setLocalStorageValue("email-id", res.data.data.email_id);
        setLocalStorageValue("client-storename", res.data.data.ClientStoreName);
        setLocalStorageValue("user-barcode", res.data.data.barcode_image);

        dispatch(fetchUserLoginSuccess(res.data.data));
        handleLoginSuccess && handleLoginSuccess();
      } else {
        dispatch(fetchUserLoginFailure({ error: res.data.message }));
      }
    })
    .catch((error) => {
      dispatch(fetchUserLoginFailure({ error: error.response.data.message }));
    });
};

// Client details

export const fetchClientDetails = (payload) => {
  return {
    type: FETCH_CLIENT_DETAILS,
    payload: payload,
  };
};

export const fetchClientDetailsSuccess = (payload) => {
  return {
    type: FETCH_CLIENT_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchClientDetailsFailure = (payload) => {
  return {
    type: FETCH_CLIENT_DETAILS_FAILURE,
    payload: payload,
  };
};

export const getClientDetails = (body) => async (dispatch) => {
  dispatch(fetchClientDetails());
  axiosLib
    .post(process.env.REACT_APP_GET_CLIENT_BACKEND_URL, body)
    // .post("https://aogapi.alwaysongrocery.net/cms/api/v1/GetClientDetail", body)
    .then((res) => {
      if (res.data.status === "success") {
        setLocalStorageValue("RSAclient-id", res.data.data.RSAClientId);
        setLocalStorageValue("api-end-point-aog", res.data.data.ApiEndPointAOG);
        setLocalStorageValue("theme-name", res.data.data.theme_name);
        setLocalStorageValue("client-name", res.data.data.client_name);
        dispatch(fetchClientDetailsSuccess(res.data.data));
      }
    })
    .catch((error) => {
      dispatch(
        fetchClientDetailsFailure({ error: error.response.data.message })
      );
    });
};

// forgot password
export const fetchCustomerForgotPassword = (payload) => {
  return {
    type: CUSTOMER_FORGOT_PASSWORD,
    payload: payload,
  };
};

export const fetchCustomerForgotPasswordSuccess = (payload) => {
  return {
    type: CUSTOMER_FORGOT_PASSWORD_SUCCESS,
    payload: payload,
  };
};

export const fetchCustomerForgotPasswordFailure = (payload) => {
  return {
    type: CUSTOMER_FORGOT_PASSWORD_FAILURE,
    payload: payload,
  };
};

export const customerForgotPassword = (body) => async (dispatch) => {
  dispatch(fetchCustomerForgotPassword());
  axios
    .post("/AogCustomerForgotPassword", body)
    .then((res) => {
      dispatch(fetchCustomerForgotPasswordSuccess(res.data));
    })
    .catch((error) => {
      dispatch(
        fetchCustomerForgotPasswordFailure({
          error: error.response.data.message,
        })
      );
    });
};

//User deatils

export const fetchUserDetails = (payload) => {
  return {
    type: FETCH_USER_DETAILS,
    payload: payload,
  };
};

export const fetchUserDetailsSuccess = (payload) => {
  return {
    type: FETCH_USER_DETAILS_SUCCESS,
    payload: payload,
  };
};

export const fetchUserDetailsFailure = (payload) => {
  return {
    type: FETCH_USER_DETAILS_FAILURE,
    payload: payload,
  };
};

export const getUserDetails = () => async (dispatch) => {
  dispatch(fetchUserDetails());
  dispatch(fetchUserDetailsSuccess());
  // dispatch(fetchUserDetailsFailure());
  // axios
  // .get(`/users`)
  // .then(res => {
  //   dispatch(fetchUserDetailsSuccess(res.data.data));
  // })
  // .catch(error => {
  //   dispatch(fetchUserDetailsFailure({ error: error.response.data.message }));
  // });
};

// Delete user account
export const deleteCustomerAccount = (payload) => {
  return {
    type: DELETE_USER_ACCOUNT,
    payload: payload,
  };
};

export const deleteUserAccountSuccess = (payload) => {
  return {
    type: DELETE_USER_ACCOUNT_SUCCESS,
    payload: payload,
  };
};

export const deleteUserAccountFailure = (payload) => {
  return {
    type: DELETE_USER_ACCOUNT_FAILURE,
    payload: payload,
  };
};

export const deleteUserAccount = (body) => async (dispatch) => {
  dispatch(deleteCustomerAccount());
  axios
    .post("/AogDeleteCustomer", body)
    .then((res) => {
      if (res.data.status === "success") {
        const store = getLocalStorageValue("selected-store-id");
        localStorage.clear();
        setLocalStorageValue("selected-store-id", store);
        window.location.replace("/");
      }
    })
    .catch((error) => {
      dispatch(
        deleteUserAccountFailure({
          error: error.response.data.message,
        })
      );
    });
};

export const clearRegisterMessage = () => ({
  type: CLEAR_REGISTER_MESSAGE,
});

export const clearLoginMessage = () => ({
  type: CLEAR_LOGIN_MESSAGE,
});

export const changeEmailUpdateAction = (payload) => {
  return {
    type: CHANGE_EMAIL_UPDATE,
    payload: payload,
  };
};

export const changeEmailUpdateSuccess = (payload) => {
  return {
    type: CHANGE_EMAIL_UPDATE_SUCCESS,
    payload: payload,
  };
};
export const changeEmailUpdateFailure = (payload) => {
  return {
    type: CHANGE_EMAIL_UPDATE_FAILURE,
    payload: payload,
  };
};

export const ClearChangeEmailUpdateResponse = (payload) => {
  return {
    type: CLEAR_CHANGE_EMAIL_UPDATE_RESPONSE,
    payload: payload,
  };
};

export const changeEmailAPI = (body) => async (dispatch) => {
  dispatch(changeEmailUpdateAction());
  axios
    .post("/AogCustomerChangeEmailID", body)
    .then((response) => {
      dispatch(changeEmailUpdateSuccess(response.data));
      if (response.data.status === "success") {
        localStorage.clear();
        window.location.replace("/");
      }
    })
    .catch((error) => {
      dispatch(
        changeEmailUpdateFailure({ error: error.response.data.message })
      );
    });
};
