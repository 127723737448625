import Pagination from "components/Common/Pagination";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogDetails } from "redux/actions";
import "./blog-card.scss";
import { getLocalStorageValue } from 'config/helper';

const BlogCard = () => {
  const dispatch = useDispatch();
  const { blogData } = useSelector(({ blog }) => blog);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  useEffect(() => {
    const body = {
      ClientStoreId: +storeId || 1,
      RSAClientId: clientId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      PageNo: 1,
    };
    dispatch(getBlogDetails(body));
  }, [dispatch]); // eslint-disable-line

  const [page, setPage] = useState(1);

  const pageLimit = 9;
  const totalPages = Math.ceil(blogData.length / pageLimit);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
    scrollToTop();
    setPage(data);
  };

  const offset = (page - 1) * pageLimit;
  const updatedBlogListsData = blogData.slice(offset, offset + pageLimit);

  return (
    <>
      <div className="blog-section">
        <div className="container">
          <div className="grid">
            {updatedBlogListsData &&
              updatedBlogListsData.map((item) => {
                return (
                  <div className="grid-items">
                    <div className="icon-center-align">
                      <img src={item.image} alt="WideIcon" />
                    </div>
                    <p className="department">Blog</p>
                    <h2>{item.title}</h2>
                    <p className="blog-detail">{item.short_description}</p>
                    <div className="read-more">
                      <button>
                        <span>Read More</span>
                      </button>
                    </div>
                  </div>
                );
              })}
          </div>
          {blogData.length ? (
            <Pagination
              pages={totalPages}
              current={page}
              onClick={handleSetPage}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
export default BlogCard;
