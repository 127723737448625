import React  from "react";
import "./featured-openings.scss";
import { useEffect } from "react";

const FeaturedOpenings = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://embed.teamengine.io/v0.2/bundle.js`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    <div className="container">
      <div id="team-engine-careers-widget" data-orgKey="fresh_market"></div>
    </div>
  );
};
export default FeaturedOpenings;
