import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import CategoryDropdown from "components/Header/MainHeader/SearchProducts/CategoryDropdown";
import {
  getSearchResultList,
  updateSearchText,
  updateSearchType,
  updateNodeFuctionalText,
  updateNodeFunctionalLevel,
  resetFilterSideBar,
} from "redux/actions";
import _ from "lodash";
import SearchDropdown from "./SearchDropdown";
import SearchIcon from "assets/icons/search.svg";
import DownIcon from "assets/icons/down.svg";
import { useNavigate } from "react-router-dom";
import { getLocalStorageValue } from "config/helper";

const SearchProducts = () => {
  const refSearchTypeDropdown = useRef();
  const navigate = useNavigate();

  const refSearchDropdown = useRef();
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentDepartment } = useSelector(({ product }) => product);
  const {
    ecom_section: { allow_ecommerce = "" },
    reorder_section: { has_visible_reorder_menu },
  } = useSelector(({ common }) => common.commonDetailsData);

  const searchTypeDropDownOption = useSelector(
    ({ common }) => common.commonDetailsData.top_dropdown_section
  );

  const [isShowSearchDropdown, setIsShowSearchDropdown] = useState(false);
  const [isShowSearchTypeDropdown, setIsShowSearchTypeDropdown] =
    useState(false);
  const [searchedText, setSearchedText] = useState("");

  let ecomSearchTypeDropDownOption =
    allow_ecommerce === "0" && has_visible_reorder_menu === "0"
      ? searchTypeDropDownOption?.filter(
        (option) => option.search_type !== "products"
      )
      : searchTypeDropDownOption;

  const { searchResultList, searchResultListLoading, searchType, searchText } =
    useSelector(({ search }) => search);

  useOnClickOutside(refSearchTypeDropdown, () =>
    setIsShowSearchTypeDropdown(false)
  );
  useOnClickOutside(refSearchDropdown, () => setIsShowSearchDropdown(false));

  useEffect(() => {
    if (currentDepartment) {
      dispatch(updateSearchType(currentDepartment));
      dispatch(updateSearchText(""));
    }
  }, [currentDepartment]); // eslint-disable-line

  useEffect(() => {
    if (allow_ecommerce === "0") {
      dispatch(updateSearchType("coupons"));
    }
  }, [dispatch, allow_ecommerce]); // eslint-disable-line

  useEffect(() => {
    setSearchedText(searchText);
  }, [searchText]); // eslint-disable-line

  const handlechange = (e) => {
    setSearchedText(e.target.value);
    if (e.target.value) {
      getSearchResult.current(e.target.value);
    }
  };

  const getSearchResult = useRef(
    _.debounce((searchedText) => {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        search: searchedText,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getSearchResultList(body));
      setIsShowSearchDropdown(true);
    }, 500)
  );

  const handleToggleCategoyDropdown = () => {
    setIsShowSearchTypeDropdown(!isShowSearchTypeDropdown);
  };

  const handleChangeSelectedOption = (optionValue) => {
    if (!isUserLoggedIn && optionValue === "list-items") {
      navigate(`/login`);
    }
    else {
      setIsShowSearchTypeDropdown(false);
      dispatch(updateSearchType(optionValue));
      dispatch(updateNodeFuctionalText(""));
      dispatch(updateNodeFunctionalLevel(""));
      dispatch(resetFilterSideBar());
      dispatch(updateSearchText(""));
      if (["products", "coupons"].includes(optionValue)) {
        navigate(`/search/${optionValue}/`);
      } else {
        navigate(`/departments/${optionValue}`);
      }
    }
  };

  const { sub_department = [] } = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section || {}
  );

  let ecomSearchOptions = [...ecomSearchTypeDropDownOption];

  if (!!sub_department.length) {
    const updatedSubDepartment = sub_department.map((sub) => {
      return {
        ...sub,
        search_type: sub.slug,
      };
    });
    ecomSearchOptions.splice(1, 0, updatedSubDepartment);
    ecomSearchOptions = ecomSearchOptions.flat();
  } else {
    ecomSearchOptions = ecomSearchTypeDropDownOption;
  }
  const selectedSearchType =
    ecomSearchOptions &&
    ecomSearchOptions.find((type) => type.search_type === searchType);

  const categoryNameList = ecomSearchTypeDropDownOption.map(
    (cat) => cat.search_type
  );

  const onSearchChange = (keyword) => {
    if (categoryNameList.includes(searchType)) {
      navigate(`/search/${searchType}/${keyword}`);
      dispatch(updateSearchText(keyword));
    } else {
      navigate(`/departments/${searchType}`);
      dispatch(updateSearchText(keyword));
    }
  };

  const handleSearchbox = (e) => {
    const { value } = e.target;
    if (e.key === "Enter") {
      if (categoryNameList.includes(searchType)) {
        navigate(`/search/${searchType}/${value}`);
        dispatch(updateSearchText(value));
        setIsShowSearchDropdown(true);
      } else {
        navigate(`/departments/${searchType}`);
        dispatch(updateSearchText(value));
        setIsShowSearchDropdown(true);
      }
    }
  };
  // ticket no:1791  clicking on search icon , search operation is working. completed on 2/22/2023 - Navina.k
  const handleSearchImage = () => {
    let searchValue = searchedText;
    if (categoryNameList.includes(searchType)) {
      navigate(`/search/${searchType}/${searchValue}`);
      dispatch(updateSearchText(searchValue));
      setIsShowSearchDropdown(true);
    } else {
      navigate(`/departments/${searchType}`);
      dispatch(updateSearchText(searchValue));
      setIsShowSearchDropdown(true);
    }
  };

  return (
    <div className="search-products-wrapper form-group">
      <div
        className="categories"
        onClick={() => handleToggleCategoyDropdown()}
        ref={refSearchTypeDropdown}
      >
        <span>All {selectedSearchType ? selectedSearchType.name : ""}</span>
        <img src={DownIcon} alt="DownIcon" />

        <CategoryDropdown
          isShow={isShowSearchTypeDropdown}
          onClose={() => setIsShowSearchTypeDropdown(false)}
          dropdownData={ecomSearchOptions}
          onSelectOption={handleChangeSelectedOption}
          selectedSearchType={
            selectedSearchType && selectedSearchType.search_type
          }
        />
      </div>
      <div
        className="search-products"
        ref={refSearchDropdown}
        onClick={() => {
          setIsShowSearchDropdown(!isShowSearchDropdown);
        }}
      >
        <div className="relative-div">
          <input
            type="text"
            // placeholder={`${selectedSearchType?.name ?? ""}`}
            onChange={handlechange}
            onKeyPress={handleSearchbox}
            value={searchedText}
          />
          <div className="icon-wrapper">
            <img
              src={SearchIcon}
              alt="SearchIcon"
              onClick={handleSearchImage}
            />
          </div>

          {searchResultList.length ? (
            <div
              className={
                isShowSearchDropdown
                  ? "search-dropdown dropdown-show"
                  : "search-dropdown dropdown-hidden"
              }
            >
              <div className="list-menu">
                <ul>
                  {searchResultList.map((product) => {
                    return (
                      <SearchDropdown
                        key={product.keyword_id}
                        productKeyWord={product}
                        onSearchChange={onSearchChange}
                        setIsShowSearchDropdown={() =>
                          setIsShowSearchDropdown(false)
                        }
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            <div
              className={
                isShowSearchDropdown
                  ? "search-dropdown dropdown-show"
                  : "search-dropdown dropdown-hidden"
              }
            >
              {!searchResultListLoading && (
                <div className="list-menu">
                  <ul>
                    <li>No Suggestion found.</li>
                  </ul>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchProducts;
