import React from "react";
import { getLocalStorageValue } from "config/helper";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const barcodeImage = getLocalStorageValue("user-barcode");
  return (
    <>
      <div ref={ref}>
        {" "}
        <img
          alt="Barcode"
          src={barcodeImage ? barcodeImage : ""}
          style={{ marginLeft: "108px", marginTop: "200px" }}
        />
      </div>
    </>
  );
});
export default ComponentToPrint;
