import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getRedeemListDetails,
  setSelectedRedeemOption,
  getRewardDetails,
} from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

import "./modal.scss";
const Description = ({ show, close, reward }) => {
  const { purchased_amount, lm_reward_id } = reward
  const dispatch = useDispatch();

  const [selectedRedeem, setSelectedRedeem] = useState({});

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const { redeemList, setRedeemSuccessMessage } = useSelector(
    ({ reward }) => reward
  );

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      lm_reward_id: lm_reward_id
    };
    dispatch(getRedeemListDetails(body));
  }, []); // eslint-disable-line

  const handleSelectedRedeem = (value, point, tierId, rewardId) => {
    setSelectedRedeem({
      value: value,
      point: point,
      tierId: tierId,
      rewardId: rewardId,
    });
  };

  const handleRedeemClick = () => {
    const { value, point, tierId, rewardId } = selectedRedeem;

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      tier_id: tierId,
      lm_reward_id: rewardId,
      points_required: point,
      coupon_value: value,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(setSelectedRedeemOption(body));
  };

  const selectSuccess = () => {
    close();
    setSelectedRedeem({});

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getRewardDetails(body));
  };
  return (
    <>
      {show ? (
        <div className="rewardmodalContainer">
          <div className="modal">
            <header className="modal_header">
              <h2 className="modal_header-title">
                {" "}
                YOUR AVAILABLE POINTS: {purchased_amount}
              </h2>
            </header>
            <div className="modal-content">
              <div className="content-text">
                {redeemList && redeemList.length ? (
                  <p>
                    Check one reward option at a time and click Redeem.
                  </p>
                ) : (
                  <p>
                    Currently you do not have any qualified rewards to redeem.
                  </p>
                )}
              </div>
              <div className="redeem-checkbox-item-list">
                {redeemList &&
                  !!redeemList.length &&
                  redeemList.map((item) => {
                    return (
                      <>
                        <div className="redeem-item-with-checkbox">
                          {item.IsItQualifiedforReward?(
                          <input
                            type="checkbox"
                            id={item.title}
                            onChange={() =>
                              handleSelectedRedeem(
                                item.coupon_value,
                                item.points_required,
                                item.tier_id,
                                item.lm_reward_id
                              )
                            }
                            checked={selectedRedeem.tierId === item.tier_id}
                          />
                          ):("")}
                          <label htmlFor={item.title}>
                            <div className={`redeem-list-info ${item.IsItQualifiedforReward === false?`redeem-imput-empty`:``}`}>
                              <p className="redeem-points">
                                {item.points_required} points
                              </p>
                              <p className="redeem-title">{item.title}</p>
                              <p className="redeem-d-none">{item.coupon_value} Discount  </p>
                            </div>
                          </label>
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>

            <div className="expireContent">
              <div>
                <p className="suggestion-txt">
                  * Only one reward can be applied at a time. Select one and
                  click Redeem.
                </p>
                {setRedeemSuccessMessage &&
                  setRedeemSuccessMessage.status === "success" && (
                    <p className="success-msg">
                      {setRedeemSuccessMessage.message}
                    </p>
                  )}
                {setRedeemSuccessMessage &&
                  setRedeemSuccessMessage.status === "error" && (
                    <p className="error-msg">Reward is not issued.</p>
                  )}
              </div>
              <div className="button-wrapper">
                {!setRedeemSuccessMessage ? (
                  <>
                    <button
                      className="redeem-btn"
                      onClick={() => handleRedeemClick()}
                      disabled={Object.keys(selectedRedeem).length === 0}
                    >
                      REDEEM
                    </button>
                    <button className="modal-close" onClick={() => close()}>
                      Close
                    </button>
                  </>
                ) : (
                  <button
                    className="success-btn"
                    onClick={() => selectSuccess()}
                  >
                    Ok
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Description;
