import CouponCard from "components/CouponCard";
import React from "react";
import "./weekly-ad-space-right-section.scss";

const WeeklyAdSpaceSectionRightSide = ({ couponList }) => {
  return (
    <div className="ads-space-section-weeklyAdd-right-side-wrapper">
      <h3 className="weekly-ad-right-side-special-promotion">
        Special Promotions
      </h3>
      <div className="ads-space-section-weeklyAdd-right-side">
        {couponList.map((coupon) => {
          return (
            <div className="weekly-ad-coupon-card">
              <CouponCard coupon={coupon} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default WeeklyAdSpaceSectionRightSide;
