import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import CartItems from "./CartItems";
import ProceedCheckout from "./ProceedCheckout";
import Breadcrumb from "components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { clearCartList, clearCateringCartList } from "redux/actions";
import "./your-cart.scss";
import ClearCartConfirmationModal from "./ClearCartConfirmationModal";
import AddToMyList from "components/AddToMyList";
import { resetSelectedCartCustomOption, addMultipleProductToList } from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

const YourCart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cartType } = useParams();
  const [isShowMyListDropdown, setShowMyListDropdown] = useState(false);
  const [isShowNewListModal, setShowNewListModal] = useState(false);
  const [isItemsSavedToCart, setIsItemsSavedToCart] = useState(false);

  const [isShowClearCartConfirmModal, setShowClearCartConfirmModal] =
    useState(false);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const {
    productCartList: groceryProductCartList,
    clearCartListLoading,
    cateringAndMealKitProductCartList,
  } = useSelector(({ cartWishlist }) => cartWishlist);

  const {
    addMultipleProductLoading
  } = useSelector(({ customlist }) => customlist);

  let productCartList = groceryProductCartList;
  if (cartType === "catering-meal") {
    productCartList = cateringAndMealKitProductCartList;
  }

  useEffect(() => {
    return () => {
      dispatch(resetSelectedCartCustomOption());
    };
  }, []); // eslint-disable-line

  const breadcrumbList = [{ lable: "Your cart", link: "" }];

  const handleClickClearCart = () => {
    setShowClearCartConfirmModal(true);
    // const body = {
    //   RSAClientId: clientId,
    //   ClientStoreId: +storeId || 1,
    //   cart_id: productCartList[0] && productCartList[0].cart_id,
    //   member_number: memberNumber,
    //   user_token: userToken,
    //   AppName: "shop",
    //   DeviceType: "web",
    //   Version: "1",
    // };

    // dispatch(clearCartList(body));
  };

  const handleClearingCart = () => {
    if (cartType === "catering-meal") {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        meal_cart_id: productCartList[0] && productCartList[0].meal_cart_id,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };

      dispatch(clearCateringCartList(body));
    } else {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        cart_id: productCartList[0] && productCartList[0].cart_id,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };

      dispatch(clearCartList(body));
    }
  };

  const { is_mealkit = "0" } = cateringAndMealKitProductCartList[0] || {};

  let yourCartHeader = "Your Cart";

  if (cartType === "catering-meal") {
    if (cartType === "catering-meal" && is_mealkit === "1") {
      yourCartHeader = "Your Meals on Go";
    } else if (cartType === "catering-meal" && is_mealkit === "2") {
      yourCartHeader = "Your Catering";
    } else {
      yourCartHeader = "Your Cart";
    }
  }


  const handleAddToWishlist = (list_id) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      items_data: productCartList.map(product => {
        return {
          product_id: product.product_id,
          qty: product.qty,
        }
      }),
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      list_id,
    };

    isUserLoggedIn &&
      dispatch(addMultipleProductToList(body)).then(() => {
        setShowMyListDropdown(false);
      });
  };


  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />
      <div className="container your-cart-section-align">
        <div className="cart-title">
          <h1>{yourCartHeader}</h1>
        </div>
        <div className="page-grid-align">
          <div>
            <p>
              There are {productCartList && productCartList.length} products in
              your cart
            </p>
          </div>
          <div className="cart-action-align">
            {/* <div className="check-reward">
              <button>Check your reward</button>
            </div> */}
            <div className="continue-shopping">
              <Link
                to={
                  cartType === "catering-meal"
                    ? "/catering-meal"
                    : "/search/products/*"
                }
              >
                <button>
                  <i className="fa-solid fa-left-long"></i>
                  <span>Continue Shopping</span>
                </button>
              </Link>
            </div>

            <AddToMyList
              isShowMyListDropdown={isShowMyListDropdown}
              isShowNewListModal={isShowNewListModal}
              setShowNewListModal={setShowNewListModal}
              setShowMyListDropdown={setShowMyListDropdown}
              title={addMultipleProductLoading ? "Saving to list" : "Add to my list"}
              handleApply={handleAddToWishlist}
            />

            <div className="clear-cart">
              {clearCartListLoading ? (
                <div className="clear-icon disabled">
                  <i className="fa-solid fa-trash-can"></i> Clearing...
                </div>
              ) : (
                <div
                  className={
                    productCartList && !!productCartList.length
                      ? "clear-icon"
                      : "clear-icon disabled"
                  }
                  onClick={handleClickClearCart}
                >
                  <i className="fa-solid fa-trash-can"></i> Clear Cart
                </div>
              )}
            </div>

            <ClearCartConfirmationModal
              isShow={isShowClearCartConfirmModal}
              onClose={() => setShowClearCartConfirmModal(false)}
              onClear={handleClearingCart}
            />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="your-cart-section">
          <div className="cart-grid">
            <div className="cart-grid-items">
              <CartItems
                productCartList={productCartList}
                cartType={cartType}
              />
            </div>
            <div className="cart-grid-items">
              <ProceedCheckout
                productCartList={productCartList}
                cartType={cartType}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default YourCart;
