import React, { useRef } from "react";
import "./dashboard.scss";
import ReactToPrint from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";

const Dashboard = ({ customerData }) => {
  const componentRef = useRef();

  return (
    <>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} />
      </div>

      <div className="dashboard-section">
        <div className="page-title">
          <h1>My Account</h1>
        </div>
        {customerData && (
          <div className="dashboard-box">
            <div className="first-box">
              <h2>Contact Information</h2>

              <div className="text-grid">
                <div className="text-grid-items">
                  <p>Name: </p>
                </div>
                <div className="text-grid-items">
                  <span>
                    {customerData.first_name + " " + customerData.last_name}
                  </span>
                </div>
              </div>
              <div className="text-grid">
                <div className="text-grid-items">
                  <p>Email: </p>
                </div>
                <div className="text-grid-items">
                  <span>{customerData.email_id}</span>
                </div>
              </div>
              <div className="text-grid">
                <div className="text-grid-items">
                  <p>Store: </p>
                </div>
                <div className="text-grid-items">
                  <span>{customerData.ClientStoreName}</span>
                </div>
              </div>

              <div className="text-grid">
                <div className="text-grid-items bg-image" style={{
                  marginLeft: "-28px",width:"450px", height:"116px",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                                                    backgroundImage: `url(${customerData ? customerData.barcode_image : ""})`,
                                                }}>
                  <p> </p>
                  {/* <img
                    alt="Barcode"
                    src={customerData ? customerData.barcode_image : ""}
                    style={{ marginLeft: "-28px",width:"400px", height:"100px" }}
                    className="img-responsive"
                  /> */}
                </div>
                <div className="text-grid-items">
                  <span></span>
                </div>
              </div>

              <ReactToPrint
                trigger={() => (
                  <button style={{minWidth: '120px',
                    height: '44px',
                    borderRadius: '4px',
                    fontSize: '17px',
                    lineHeight: '28px'}}>
                    <i className="fa-solid fa-print"></i>
                    <span>Print Member Number</span>
                  </button>
                )}
                content={() => componentRef.current}
              />
            </div>
          
          </div>
        )}
      </div>
    </>
  );
};
export default Dashboard;
