import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./catering-meals-order.scss";
import { getCateringAndMealKitCustomerOrderDetails } from "redux/actions";
import { Link } from "react-router-dom";
import Pagination from "components/Common/Pagination";
import { getLocalStorageValue } from 'config/helper';

const CateringMealsOrder = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const {
    cateringAndMealsOrderList,
    cateringAndMealsOrderListLimit,
    cateringAndMealsOrderTotalRecord,
    cateringAndMealsOrderLoading
  } = useSelector(({ customer }) => customer);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const [page, setPage] = useState(1);

  const totalPages = Math.ceil(
    +cateringAndMealsOrderTotalRecord / +cateringAndMealsOrderListLimit
  );

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      PageNo: page,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCateringAndMealKitCustomerOrderDetails(body));
  }, [dispatch, clientId, memberNumber, userToken, storeId, isUserLoggedIn, page]);

  const dateFormat = (inputDate, format) => {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    format = format.replace("MM", month.toString().padStart(2, "0"));

    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleSetPage = (data) => {
    scrollToTop();
    setPage(data);
  };

  // const offset = (page - 1) * +cateringAndMealsOrderListLimit;
  // const updatedCateringAndMealsOrderList = cateringAndMealsOrderList.slice(
  //   offset,
  //   offset + +cateringAndMealsOrderListLimit
  // );

  return (
    <>
      <div className="order-section">
        <div className="order-page-title">
          <h1>Catering Orders</h1>
          {/* <button onClick={handleAddNewAddress}>Add</button> */}
        </div>
        <div className="order-table-wrapper">
          {cateringAndMealsOrderList &&
            cateringAndMealsOrderList.length > 0 ? (

            <div className="scrollable">
              <table className="responsive">
                <tbody>
                  <tr>
                    <th align="left">Date</th>
                    <th align="left">Order #</th>
                    <th align="left">Store</th>
                    <th align="left">Amount</th>
                    <th align="left">Pick up/Del</th>
                    <th align="left">Status</th>
                    <th align="left">Action</th>
                  </tr>
                  {cateringAndMealsOrderList.map((order) => {
                    return (
                      <tr key={order.meal_order_id}>
                        <td>
                          {dateFormat(
                            order.order_placed_on.split(" ")[0],
                            "MM-dd-yyyy"
                          )}
                        </td>
                        <td>{order.order_number}</td>
                        <td>{order.ClientStoreName}</td>
                        <td>{`$${order.total_amount}`}</td>
                        <td>{order.shipping_method_name}</td>
                        <td>{order.status}</td>
                        <td>
                          <Link
                            to={`/checkout-invoice/catering-meal/${order.meal_order_id}`}
                            key={order.meal_order_id}
                          >
                            <button>View</button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

          ) : (
            <div className="no-order-message"> {cateringAndMealsOrderLoading ? "Please wait..." : "No Orders yet!"}</div>
          )}
        </div>
        <div className="mobile-catering-table-wrapper">
          {cateringAndMealsOrderList &&
            cateringAndMealsOrderList.length > 0 ? (
            <div className="scrollable">
              {cateringAndMealsOrderList.map((order) => {
                return (
                  <table className="table-responsive" key={order.meal_order_id}>
                    <tbody>
                      <tr className="table-row">
                        <th align="left">Date</th>
                        <td>
                          {dateFormat(
                            order.order_placed_on.split(" ")[0],
                            "MM-dd-yyyy"
                          )}
                        </td>
                      </tr>

                      <tr className="table-row">
                        <th align="left">Order #</th>
                        <td>{order.order_number}</td>
                      </tr>

                      <tr className="table-row">
                        <th align="left">Store</th>
                        <td>{order.ClientStoreName}</td>
                      </tr>

                      <tr className="table-row">
                        <th align="left">Amount</th>
                        <td>{`$${order.total_amount}`}</td>
                      </tr>

                      <tr className="table-row">
                        <th align="left">Pick up/Del</th>
                        <td>{order.shipping_method_name}</td>
                      </tr>

                      <tr className="table-row">
                        <th align="left">Status</th>
                        <td>{order.status}</td>
                      </tr>

                      <td>
                        <Link
                          to={`/checkout-invoice/catering-meal/${order.meal_order_id}`}
                          key={order.meal_order_id}
                        >
                          <button>View</button>
                        </Link>
                      </td>
                    </tbody>
                  </table>
                );
              })}
            </div>
          ) : (
            <div className="no-order-message"> {cateringAndMealsOrderLoading ? "Please wait..." : "No Orders yet!"}</div>
          )}
        </div>
        {cateringAndMealsOrderList && cateringAndMealsOrderList.length > 0 ? (
          <Pagination
            pages={totalPages}
            current={page}
            onClick={handleSetPage}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};
export default CateringMealsOrder;
