import Slider from "react-slick/lib/slider";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import CampaignProductCard from "components/CampaignProductCard";
import "./campaign-product-list-slider.scss";

const CampaignProductListSlider = ({ products, addButtonText }) => {
  const settings = {
    dots: false,
    infinite: products.length > 6,
    slidesToShow: products.length > 1 ? 6 : products.length,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          draggable: false,
        },
      },
    ],
  };
  return (
    <div className="container">
      <div className="product-list-wrapper">
        <Slider {...settings}>
          {products.map((product) => {
            return <CampaignProductCard product={product} key={product.product_id} addButtonText={addButtonText} />
          })}
        </Slider>
      </div>
    </div>
  );
};
export default CampaignProductListSlider;
