import React from "react";
import CheckoutDetails from "./CheckoutDetails";
import CheckoutInfo from "./CheckoutInfo";
import Breadcrumb from "components/Common/Breadcrumb";
import "./checkout.scss";

const Checkout = () => {
  const breadcrumbList = [{ lable: "Checkout", link: "" }];
  return (
    <>
      <Breadcrumb breadcrumbList={breadcrumbList} />

      <CheckoutDetails />

      <CheckoutInfo />
    </>
  );
};
export default Checkout;
