import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEbtCardInformation } from "redux/actions";
import { Link, useNavigate } from "react-router-dom";
import "./ebt-card.scss";
import { getLocalStorageValue } from 'config/helper';

const EBTCard = ({ ebtBalance }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const { ebtCardInformation, fetchEbtCardInformationLoading } = useSelector(
    ({ customer }) => customer
  );
  const [ebtBalanceResponse, setEbtBalanceResponse] = useState(null)

  useEffect(() => {
    if (ebtCardInformation) {
      const { status } = ebtCardInformation
      if (status === "success") window.location.href = ebtCardInformation.data.redirectURL
    }
  }, [ebtCardInformation]) // eslint-disable-line


  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      return_callback_url: window.location.hostname
    };
    !ebtBalance && dispatch(getEbtCardInformation(body))
  }, [dispatch, clientId, memberNumber, userToken, ebtBalance]); // eslint-disable-line

  useEffect(() => {
    if (ebtBalance) {
      const decodeData = window.atob(ebtBalance)
      setEbtBalanceResponse(JSON.parse(decodeData))
    }
  }, [ebtBalance]) // eslint-disable-line

  const handleClickCheckBalance = () => {
    navigate('/myaccount')
    setEbtBalanceResponse(null)
  }


  return (
    <div className="ebt-card-section">
      <div className="page-title">
        <h1>EBT SNAP</h1>
      </div>

      <div className="ebt-card-box">
        <div className="first-box">
          <h2>EBT SNAP Balance Inquiry</h2>
          {fetchEbtCardInformationLoading &&
            <div className="text-grid">
              <p>Please wait... </p>
            </div>}
          {ebtBalanceResponse && ebtBalanceResponse.status === "success" && (
            <div className="text-grid">
              <div className="text-grid-items">
                <p className="success-message">{ebtBalanceResponse && ebtBalanceResponse.message} </p>
              </div>
            </div>
          )}
          {ebtBalanceResponse && ebtBalanceResponse.status === "error" && (
            <div className="text-grid">
              <div className="text-grid-items">
                <p className="error-message">{ebtBalanceResponse && ebtBalanceResponse.message} </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="continue-shopping">
        <Link to="/">
          <button>
            <i className="fa-solid fa-left-long"></i>
            <span>Continue Shopping</span>
          </button>
        </Link>
        {ebtBalanceResponse && (
          <button onClick={handleClickCheckBalance}>
            <span>Check Balance</span>
          </button>
        )}
      </div>
    </div>
  );
};
export default EBTCard;
