import React from "react";
import "./rewards.scss";
import RewardsCard from "./rewardsCard";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useSelector } from "react-redux";

export default function Rewards() {
  const breadcrumbList = [{ lable: "Rewards", link: "", searchTag: "" }];

  const {
    rewardData: { ads_section = null },
  } = useSelector(({ reward }) => reward);

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={"Rewards"} //Need to pass title through api res
      />
      <div className="rewards-add-space">
        {ads_section && ads_section.ads_image ? (
          <div className="page-container">
            <img src={ads_section.ads_image} alt="banner" />
          </div>
        ) : (
          ""
        )}
      </div>
      <>
        <RewardsCard />
      </>
    </>
  );
}
