import {
    FETCH_WEEKLYAD_DATA,
    FETCH_WEEKLYAD_DATA_SUCCESS,
    FETCH_WEEKLYAD_DATA_FAILURE,
  
  } from "redux/constants/actionTypes";
  
  import axios from "config/axios";
import { getLocalStorageValue } from "config/helper";
  
  // fetch about data
  export const fetchWeeklyad = (payload) => {
    return {
      type: FETCH_WEEKLYAD_DATA,
      payload: payload,
    };
  };
  export const fetchWeeklyadSuccess = (payload) => {
    return {
      type: FETCH_WEEKLYAD_DATA_SUCCESS,
      payload: payload,
    };
  };
  export const fetchWeeklyadFailure = (payload) => {
    return {
      type: FETCH_WEEKLYAD_DATA_FAILURE,
      payload: payload,
    };
  };
  
  export const getWeeklyad = (body) => async (dispatch) => {
    dispatch(fetchWeeklyad());
  
    axios
      .post("/AogGetWeeklyAds", body)
      .then((res) => {
        if(res?.data?.data && res?.data?.data?.length === 1)
        {
            window.location.replace(`weekly-ads/${body.ClientStoreId}/${body.StoreName}`)
        }
        else
        {
          dispatch(fetchWeeklyadSuccess(res.data.data));
        }
      })
      .catch((error) => {
        dispatch(fetchWeeklyadFailure({ error: error.data.message }));
      });
  };
  