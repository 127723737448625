import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import CouponListingPageSidebar from "components/CouponListingPage/Sidebar";
import CouponList from "components/CouponList";
import CouponListingPageAction from "components/CouponListingPage/Actions";
import HotOffers from "components/HotOffers";
import {
  getHotOffersData,
  getCouponListDetails,
  getCouponCategoriesDetails,
} from "redux/actions";
import { getLocalStorageValue } from "config/helper";

import "./coupon.scss";

const Coupon = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const breadcrumbList = [{ lable: "Coupons", link: "", searchTag: "" }];

  const [categoryId, setcategoryId] = useState("0");

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getHotOffersData(body));
  }, [storeId]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_category_id: categoryId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCouponListDetails(body));
  }, [categoryId, storeId]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCouponCategoriesDetails(body));
  }, [storeId]); // eslint-disable-line

  const hotOffersData = useSelector(({ hotOffers }) => hotOffers.hotOffersData);
  const hotOfferTitle = hotOffersData.title;
  const adverstiseSectionData = hotOffersData.adverstise_section;

  const couponListsData = useSelector(({ coupon }) => coupon.couponData);
  const couponDataLoading = useSelector(({ coupon }) => coupon.couponDataLoading);
  const couponCategoriesData = useSelector(
    ({ coupon }) => coupon.couponCategoriesData
  );
  
  const handleCouponlist = (category_id) => {
    setcategoryId(category_id);
  };

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={`Coupons`}
      />
      <div className="container">
        <div className="coupon-listing-page-wrapper">
          <div className="listing-page-wrapper-items">
            <CouponListingPageSidebar
              onChange={handleCouponlist}
            />
          </div>
          <div className="listing-page-wrapper-items">
            {/* <AdsSpaceSection /> */}
            <CouponListingPageAction
              onChange={handleCouponlist}
              totalCuponCount={couponListsData.length}
            />
            {!couponDataLoading &&
              <CouponList couponListsData={couponListsData} />
            }
          </div>
        </div>
        <HotOffers
          title={hotOfferTitle}
          adverstiseSectionData={adverstiseSectionData}
        />
      </div>
    </>
  );
};
export default Coupon;
