import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetCheckoutEbtState } from "redux/actions";
import { Link } from "react-router-dom";
import Moment from "moment";
import "./order-confirmation.scss";
import "./order-table.scss";

const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const {
    savePlaceOrder,
    ebtPaymentResponse,
    paymentTransactionId,
    paymentTransactionType,
  } = useSelector(({ checkout }) => checkout);

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  const copyRightSectionData = useSelector(
    ({ common }) => common.commonDetailsData.footer_section
  );

  useEffect(() => {
    return () => {
      localStorage.removeItem("last_new_order_id");
      dispatch(resetCheckoutEbtState());
    };
  }, []); // eslint-disable-line

  const dateFormat = (inputDate, format, moment) => {
    if (moment) {
      const date = Moment(inputDate).format("MM-DD-YYYY");
      return date;
    } else {
      const date = new Date(inputDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      format = format.replace("MM", month.toString().padStart(2, "0"));

      if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
      } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
      }
      format = format.replace("dd", day.toString().padStart(2, "0"));

      return format;
    }
  };

  const formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, "");

    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  const {
    order_number,
    customer_email,
    shipping_date,
    shipping_day,
    shipping_time,
    order_placed_on,
    customer_first_name,
    customer_last_name,
    transaction_id,
    pickup_location: {
      AddressLine1 = "",
      AddressLine2 = "",
      City = "",
      StateName = "",
      StoreEmail = "",
      StorePhoneNumber = "",
      ZipCode = "",
    },
  } = savePlaceOrder || {};

  const { ebt_card_balance_info, ebt_initiate_tran_id } =
    (ebtPaymentResponse && ebtPaymentResponse.data) || {};

  return (
    <>
      <div className="order-confirmation-wrapper">
        <div className="order-confirmation">
          <div className="confirmation-header">
            <h2>Thank you for your order</h2>
          </div>

          <table className="shop_table my_account_orders">
            <tbody>
              <tr className="order">
                <td className="order-number" data-title="" colSpan={1}>
                  <img
                    src={headerSectionData.rsa_client_logo}
                    alt={headerSectionData.image_alt_text}
                    className="logo-width-100"
                  />
                </td>

                <td className="order-actions" data-title="" colSpan={3}>
                  <img
                    src={copyRightSectionData.powered_by}
                    alt="PowerdByImage"
                  />
                </td>
              </tr>

              <tr className="order">
                <td className="order-number" data-title="" colSpan={1}>
                  <strong>
                    {customer_first_name} {customer_last_name},
                  </strong>{" "}
                  Your order has been received.
                  <br></br>
                  You will receive an email confirmation shortly on{" "}
                  <strong> {customer_email}</strong>
                </td>

                <td className="order-actions" data-title="" colSpan={3}>
                  <h4>Your pickup location:</h4>
                  <p>
                    {AddressLine1} {AddressLine2}, {City}, {StateName},{" "}
                    {ZipCode}
                    <br></br>
                  </p>
                </td>
              </tr>

              <tr className="order">
                <td className="order-number" data-title="" colSpan={1}>
                  <p className="order-numer">
                    Order Placed{" "}
                    <strong>
                      {dateFormat(order_placed_on.split(" ")[0], "MM-dd-yyyy")}
                    </strong>
                  </p>
                  <p className="order-number">
                    <strong>Order number is: {order_number}</strong>
                  </p>
                </td>

                <td className="order-actions" data-title="" colSpan={3}>
                  <h4>Pickup Date:</h4>

                  <p>
                    {shipping_day},{" "}
                    {dateFormat(shipping_date, "MM-dd-yyyy", "moment")}
                    <br></br>
                    {shipping_time}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          {ebt_initiate_tran_id ? (
            <p className="">
              <strong>
                Your EBT Payment Transaction ID is : {ebt_initiate_tran_id}
              </strong>
            </p>
          ) : null}
          {ebt_card_balance_info ? (
            <p className="">{ebt_card_balance_info}</p>
          ) : null}
          {transaction_id ? (
            <p className="">
              Your Payment Transaction ID is : {transaction_id}
            </p>
          ) : (
            ""
          )}
          {paymentTransactionId &&
          paymentTransactionType === "authorize.net" ? (
            <p className="">
              Your Authorize Payment Transaction ID is : {paymentTransactionId}
            </p>
          ) : null}
          {paymentTransactionId && paymentTransactionType === "stripe" ? (
            <p className="">
              Your Stripe Payment Token ID is : {paymentTransactionId}
            </p>
          ) : null}
          <br />
          <div className="confirmation-body">
            {/* <p className="order-number">Order number is: {order_number}</p>
            <span className="email-us">
              You will receive an email confirmation shortly on
              <strong> {customer_email}</strong>
            </span>
            <div className="pickup-grid">
              <div className="pickup-data">
                <div className="pickup-date">
                  <h4>Pickup Date:</h4>

                  <p>
                    {shipping_day}, {shipping_date}
                    <br></br>
                    {shipping_time}
                  </p>
                </div>
                <div className="pickup-loaction">
                  <h4>Your pickup location:</h4>
                  <p>
                    {AddressLine1} {AddressLine2}, {City} 
                    <br></br>
                    {StateName}, {ZipCode}
                  </p>
                  <span className="email-us">
                    Questions? Call {StorePhoneNumber} or
                  </span>
                  <a
                    href={`mailto:${StoreEmail}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Email us
                  </a>
                </div>
              </div>
            </div> */}

            <span className="order-page-link">
              You can check status of this order in your
              <Link to="/myaccount"> account.</Link>
            </span>

            <span className="email-us">
              Questions? Call {formatPhoneNumber(StorePhoneNumber)} or
            </span>
            <a href={`mailto:${StoreEmail}`} target="_blank" rel="noreferrer">
              Email us
            </a>
            <span className="back-to-home-btn">
              <Link to="/">Back to home</Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderConfirmation;
