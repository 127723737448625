import React from "react";
import "./promotion.scss";
import { useSelector } from "react-redux";
import CampaignProductListSlider from "../CampaignProductListSlider";
import { Link } from "react-router-dom";

const Promotion = () => {
  const promotions = useSelector(
    ({ homepage }) => homepage.offersWithCampaignProductData
  );
  return (
    <>
      {promotions && promotions.length > 0?(
        <div className="campaign campaign-product-card">
          {promotions && promotions.map((promotion) => {
            return (
              <div key={promotion.campaign_id} className="compaign-wrapper">
                <div className="container campaign-tab-alignment">
                  <div className="tab-alignment">
                    <ul>
                      <li className={"active-offer-type"}>
                        {promotion.campaign_name}
                      </li>
                    </ul>
                <div className="campaign-link">
                  <Link to={`${promotion.cammpagin_slug}`}> View All </Link>
                </div>
                  </div>
                </div>
                <CampaignProductListSlider products={promotion.campaign_products} addButtonText="Add to Cart" />
              </div>
            )
          })}
        </div>
      ):("")}
    </>
  )
}
export default Promotion;
