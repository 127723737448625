import React from "react";
import { useSelector } from "react-redux";
import ShopByDepartment from "./ShopByDepartment/index";
import NavMenu from "./NavMenu/index";
import "./navigation-bar.scss";
import { Link } from "react-router-dom";
import { getLocalStorageValue } from 'config/helper';

const NavigationBar = () => {
  // const headerSectionData = useSelector(
  //   ({ common }) => common.commonDetailsData.header_section
  // );

  const { reorder_section } = useSelector(
    ({ common }) => common.commonDetailsData
  );
  const header_section = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const { storeListData } = useSelector(({ store }) => store);
  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );


  const { allow_ecommerce = "1" } = selectedStore || {};

  return (
    <div className="navigation-bar">
      <div className="container">
        <div className="navigation-bar-wrapper">
          <div className="menu">
            <div className="wrapper">
              {allow_ecommerce === "1" ? <ShopByDepartment /> : ""}

              {isUserLoggedIn && (allow_ecommerce === "1" && reorder_section.has_visible_reorder_menu === "1") ? (
                <button className="primary-outlined-button">
                  <Link to={`/department/reorder`}>
                    {reorder_section.name}
                  </Link>
                </button>
              ) : (
                ""
              )}
            </div>
            <NavMenu />
          </div>
          <div className="download-app">
            {header_section.button_show_download_our_app === "1" && (
              <Link className="secondary-button" to={`/mobapp`} rel="noreferrer">
                Download our App
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
