import {
  FETCH_REWARD_DATA,
  FETCH_REWARD_DATA_SUCCESS,
  FETCH_REWARD_DATA_FAILURE,
  FETCH_REDEEM_LIST,
  FETCH_REDEEM_LIST_SUCCESS,
  FETCH_REDEEM_LIST_FAILURE,
  SET_REDEEM_OPTION,
  SET_REDEEM_OPTION_SUCCESS,
  SET_REDEEM_OPTION_FAILURE,
} from "redux/constants/actionTypes";

let initialState = {
  rewardData: [],
  rewardDataLoading: true,
  rewardDataError: "",
  redeemList: [],
  redeemListLoading: true,
  redeemListError: "",
  setRedeemSuccessMessage: "",
  setRedeemLoading: true,
  setRedeemError: "",
};

const rewardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REWARD_DATA:
      return {
        ...state,
        rewardDataLoading: true,
      };
    case FETCH_REWARD_DATA_SUCCESS:
      return {
        ...state,
        rewardDataLoading: false,
        rewardData: action.payload,
        setRedeemSuccessMessage: "",
      };
    case FETCH_REWARD_DATA_FAILURE:
      return {
        ...state,
        rewardDataError: action.payload.error,
      };

    case FETCH_REDEEM_LIST:
      return {
        ...state,
        redeemListLoading: true,
      };
    case FETCH_REDEEM_LIST_SUCCESS:
      return {
        ...state,
        redeemListLoading: false,
        redeemList: action.payload,
      };
    case FETCH_REDEEM_LIST_FAILURE:
      return {
        ...state,
        redeemListError: action.payload.error,
      };

    case SET_REDEEM_OPTION:
      return {
        ...state,
        setRedeemLoading: true,
      };
    case SET_REDEEM_OPTION_SUCCESS:
      return {
        ...state,
        setRedeemLoading: false,
        setRedeemSuccessMessage: action.payload,
      };
    case SET_REDEEM_OPTION_FAILURE:
      return {
        ...state,
        setRedeemError: action.payload.error,
      };

    default:
      return state;
  }
};

export default rewardReducer;
