import React from "react";
import "./add-space-section-feature.scss";
const AdsSpaceSection = ({ adsDetails }) => {
  return (
    <div>
      {adsDetails &&
      adsDetails.campagin_data &&
      adsDetails.campagin_data.campaign_banner_img ? (
        <div className="ads-space-section-seller">
          <img
            src={adsDetails.campagin_data.campaign_banner_img}
            alt="banner"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default AdsSpaceSection;
