import Dropdown from "components/Common/Dropdown";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getStoreDetails,
  selectCheckoutStoreType,
  getStoreTimeSlots,
  selectCheckoutTimeSlot,
  selectPickupType
} from "redux/actions";
import "./delivery-info.scss";
import { useParams } from 'react-router-dom';
import { getLocalStorageValue } from 'config/helper';

const DeliveryInfo = ({ selectDateAndTimeOptions = [], handleSelectPaymentMethod }) => {
  const dispatch = useDispatch();
  const { checkoutType } = useParams();
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID, storeDetails } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const [deliveryType, setDeliveryType] = useState("");
  const [pickupType, setPickupType] = useState("later");
  const [selectDropDownedOption, setSelectDropDownedOption] = useState();

  const { checkoutStoreType, isFollowedEbtPayment, checkoutTimeSlot: {
    shipping_day = "",
    shipping_date = "",
    shipping_time = "",


  }, } = useSelector(({ checkout }) => checkout);
  const { allow_pickup = "0",
    allow_delivery = "0",
    allow_mealkit_pickup = "0",
    allow_mealkit_delivery = "0",
    allow_catering_pickup = "0",
    allow_catering_delivery = "0"
  } = storeDetails || {};

  const {
    cateringAndMealKitProductCartList,
  } = useSelector(({ cartWishlist }) => cartWishlist);

  const { is_mealkit = "0" } = cateringAndMealKitProductCartList[0] || {}

  useEffect(() => {
    if (selectDateAndTimeOptions && selectDateAndTimeOptions.length) {
      setSelectDropDownedOption(selectDateAndTimeOptions[0].title);
      dispatch(selectCheckoutTimeSlot(selectDateAndTimeOptions[0].value));
    }
  }, [dispatch, selectDateAndTimeOptions && selectDateAndTimeOptions.length]); // eslint-disable-line

  useEffect(() => {
    //grocery
    if (storeDetails && !checkoutType) {
      if (allow_pickup === "1") {
        setDeliveryType("store_pickup");
        dispatch(selectCheckoutStoreType("store_pickup"));
      } else {
        setDeliveryType("delivery");
        dispatch(selectCheckoutStoreType("delivery"));
      }
    }
    //mealkit
    if (storeDetails && checkoutType === "catering-meal" && is_mealkit === "1") {
      if (allow_mealkit_pickup === "1") {
        setDeliveryType("store_pickup");
        dispatch(selectCheckoutStoreType("store_pickup"));
      } else {
        setDeliveryType("delivery");
        dispatch(selectCheckoutStoreType("delivery"));
      }
    }
    //catering
    if (storeDetails && checkoutType === "catering-meal" && is_mealkit === "2") {
      if (allow_catering_pickup === "1") {
        setDeliveryType("store_pickup");
        dispatch(selectCheckoutStoreType("store_pickup"));
      } else {
        setDeliveryType("delivery");
        dispatch(selectCheckoutStoreType("delivery"));
      }

    }
  }, [storeDetails, is_mealkit, checkoutType]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getStoreDetails(body));
  }, [userToken, storeId]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const url = checkoutType === "catering-meal" ? '/GetCateringAndMealkitPickupSlots' : '/AogGetStoreTimeSlots'
    dispatch(getStoreTimeSlots(body, url));
  }, [dispatch, storeId, checkoutType]); // eslint-disable-line

  const handleChange = (e) => {
    const { value } = e.target;
    setDeliveryType(value);
    dispatch(selectCheckoutStoreType(value));
    handleSelectPaymentMethod(value)
  };
  const handlePickupTypeChange = (e) => {
    const { value } = e.target;
    setPickupType(value);
    dispatch(selectPickupType(value));
  };


  const handleChangeDropDownOption = (value, name) => {
    setSelectDropDownedOption(name);
    dispatch(selectCheckoutTimeSlot(value));
  };


  return (
    <div className="delivery-info-wrapper">
      {isFollowedEbtPayment && <div className="ebt-checkout-information">
        <span><strong>Shipping Method :</strong> {checkoutStoreType === 'store_pickup' ? "Store Pickup" : "Delivery"}</span>
        <span><strong>Shipping Day :</strong> {shipping_day}</span>
        <span><strong>Shipping Date :</strong> {shipping_date}</span>
        <span><strong>Shipping Time :</strong> {shipping_time}</span>
      </div>}

      {!isFollowedEbtPayment && <div className="delivery-info-section">
        {/* // Grocery */}
        {!checkoutType && (
          <div className="grid">
            <div className="grid-items">
              <p>Choose store pickup or delivery</p>
              {storeDetails && (
                <div className="radio-button-alignment">
                  {allow_pickup === "1" ? (
                    <div>
                      <input
                        type="radio"
                        value="store_pickup"
                        id="pickup"
                        name="deliveryType"
                        onChange={handleChange}
                        checked={deliveryType === "store_pickup"}
                      />
                      <label htmlFor="pickup">Pickup</label>
                    </div>
                  ) : (
                    ""
                  )}
                  {allow_delivery === "1" ? (
                    <div>
                      <input
                        type="radio"
                        value="delivery"
                        id="delivery"
                        name="deliveryType"
                        onChange={handleChange}
                        checked={deliveryType === "delivery"}
                      />
                      <label htmlFor="delivery">Delivery</label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
            <div className="grid-items">
              <p>
                {checkoutStoreType === "store_pickup"
                  ? "Choose pickup date and time"
                  : "Choose delivery date and time"}
              </p>

              <div className="custom-date-picker">
                <Dropdown
                  optionList={selectDateAndTimeOptions || []}
                  lable=""
                  iconTag={<i className="fa-solid fa-clock"></i>}
                  value={selectDropDownedOption}
                  placeholder="Select"
                  defaultValue={
                    selectDateAndTimeOptions && selectDateAndTimeOptions[0] && selectDateAndTimeOptions[0].value
                  }
                  onChange={handleChangeDropDownOption}
                />
              </div>
            </div>
          </div>
        )}
        {/* // Catering and mealkit */}
        {checkoutType === "catering-meal" && (
          <div className="grid">
            <div className="grid-items">
              <p>Choose store pickup or delivery</p>
              {storeDetails && (
                <div className="radio-button-alignment">
                  {allow_mealkit_pickup === "1" && is_mealkit === "1" ? (
                    <div>
                      <input
                        type="radio"
                        value="store_pickup"
                        id="pickup"
                        name="deliveryType"
                        onChange={handleChange}
                        checked={deliveryType === "store_pickup"}
                      />
                      <label htmlFor="pickup">Pickup</label>
                    </div>
                  ) : (
                    ""
                  )}
                  {allow_mealkit_delivery === "1" && is_mealkit === "1" ? (
                    <div>
                      <input
                        type="radio"
                        value="delivery"
                        id="delivery"
                        name="deliveryType"
                        onChange={handleChange}
                        checked={deliveryType === "delivery"}
                      />
                      <label htmlFor="delivery">Delivery</label>
                    </div>
                  ) : (
                    ""
                  )}
                  {allow_catering_pickup === "1" && is_mealkit === "2" ? (
                    <div>
                      <input
                        type="radio"
                        value="store_pickup"
                        id="pickup"
                        name="deliveryType"
                        onChange={handleChange}
                        checked={deliveryType === "store_pickup"}
                      />
                      <label htmlFor="pickup">Pickup</label>
                    </div>
                  ) : (
                    ""
                  )}
                  {allow_catering_delivery === "1" && is_mealkit === "2" ? (
                    <div>
                      <input
                        type="radio"
                        value="delivery"
                        id="delivery"
                        name="deliveryType"
                        onChange={handleChange}
                        checked={deliveryType === "delivery"}
                      />
                      <label htmlFor="delivery">Delivery</label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {is_mealkit === "1" && deliveryType === "store_pickup" && (
                <div className="radio-button-alignment">
                  <div>
                    <input
                      type="radio"
                      value="asap"
                      id="asap"
                      name="pickupType"
                      onChange={handlePickupTypeChange}
                      checked={pickupType === "asap"}
                    />
                    <label htmlFor="asap">ASAP</label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      value="later"
                      id="later"
                      name="pickupType"
                      onChange={handlePickupTypeChange}
                      checked={pickupType === "later"}
                    />
                    <label htmlFor="later">Schedule for later</label>
                  </div>

                </div>
              )}
            </div>
            {is_mealkit === "1" && (checkoutStoreType === "store_delivery" || pickupType === "later") && (
              <div className="grid-items">
                <p>
                  {checkoutStoreType === "store_pickup"
                    ? "Choose pickup date and time"
                    : "Choose delivery date and time"}
                </p>

                <div className="custom-date-picker">
                  <Dropdown
                    optionList={selectDateAndTimeOptions || []}
                    lable=""
                    iconTag={<i className="fa-solid fa-clock"></i>}
                    value={selectDropDownedOption}
                    placeholder="Select"
                    defaultValue={
                      selectDateAndTimeOptions && selectDateAndTimeOptions[0] && selectDateAndTimeOptions[0].value
                    }
                    onChange={handleChangeDropDownOption}
                  />
                </div>
              </div>
            )}
            {is_mealkit === "2" && (
              <div className="grid-items">
                <p>
                  {checkoutStoreType === "store_pickup"
                    ? "Choose pickup date and time"
                    : "Choose delivery date and time"}
                </p>

                <div className="custom-date-picker">
                  <Dropdown
                    optionList={selectDateAndTimeOptions || []}
                    lable=""
                    iconTag={<i className="fa-solid fa-clock"></i>}
                    value={selectDropDownedOption}
                    placeholder="Select"
                    defaultValue={
                      selectDateAndTimeOptions && selectDateAndTimeOptions[0] && selectDateAndTimeOptions[0].value
                    }
                    onChange={handleChangeDropDownOption}
                  />
                </div>
              </div>
            )}
          </div>
        )}

      </div>}
    </div>
  );
};
export default DeliveryInfo;
