import React from "react";
import "./checkout-details.scss";
import { useSelector } from "react-redux";

const CheckoutDetails = () => {
  const {
    isFollowedEbtPayment
  } = useSelector(({ checkout }) => checkout);
  console.log(isFollowedEbtPayment)
  return (
    <div className="checkout-details-section">
      <div className="container">
        <div className="page-title">
          <h1>Checkout</h1>
        </div>
        <div className="enter-code-coupon">
          <p>
            {isFollowedEbtPayment ? (
                <span>
                Your EBT payment is successful.  Complete the remaining amount payment by credit card to place an order.
              </span>
            ) : (
              <span>
              Choose pickup or delivery, date time, apply available coupons and
              place order
            </span>
            )}
            
          </p>
        </div>
        {/* <div className="enter-code-coupon">
          <p>
            Have a coupon? <span>Click here to enter your code</span>
          </p>
        </div>
         <div className="apply-coupon-code">
          <div className="apply-coupon-code-style">
            <div className="form-control">
              <label>If you have a coupon code, Please apply it below</label>
              <div className="apply-coupon-relative">
                <input type="text" placeholder="SAVE20" />
                <div className="remove-button">
                  <button>Remove</button>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  );
};
export default CheckoutDetails;
