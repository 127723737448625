import React, { useState, useEffect } from "react";
import './radio-group.scss'
import { useDispatch } from 'react-redux';
import {
    handleUpdateCateringAndMealCustomOption
} from "redux/actions";

const CateringRadioGroup = ({
    groupData
}) => {
    const dispatch = useDispatch()
    const { values, title, is_required, title: label, is_add_option_price, id: groupId } = groupData

    const [selectedOption, setSelectedOption] = useState();

    useEffect(() => {

        const defaultSelectedOptionData = values.filter(option => option.is_default === "1")
        const updatedDefaultOptionList = defaultSelectedOptionData.map(ele => {
            return {
                ...ele,
                is_add_option_price,
                label
            }
        })
        defaultSelectedOptionData && defaultSelectedOptionData[0] && setSelectedOption(defaultSelectedOptionData[0].value)
        defaultSelectedOptionData && defaultSelectedOptionData[0] &&
            dispatch(handleUpdateCateringAndMealCustomOption({ [groupId]: [...updatedDefaultOptionList] }))

    }, [values]) // eslint-disable-line


    const handleChangeOption = (optionValue, option) => {
        const { id, option_id, price, value } = option
        setSelectedOption(optionValue)
        dispatch(handleUpdateCateringAndMealCustomOption({ [groupId]: [{ id, is_add_option_price, label, option_id, price, value }] }))
    }
    return (
        <div className="catering-radio">
            <p><strong>{title}</strong>{is_required === "1" && <span>*</span>}</p>
            <div className="catering-radio-item-list">
                {values && values.length && values.map((option => {
                    return <div className="catering-item-with-radio" key={option.id}>
                        <input
                            type="radio"
                            id={option.id}
                            onChange={() => handleChangeOption(option.value, option)}
                            checked={option.value === selectedOption}
                        />
                        <label htmlFor={option.id}>
                            <span>{option.value} {option.price !== "0.00" ? `- $${option.price}` : ""}</span>
                        </label>
                    </div>
                }))}
            </div>
        </div>
    );
};

export default CateringRadioGroup;
