import React, { useEffect, useState } from "react";
import "./couponsidebar.scss";
import { useSelector } from "react-redux";

const Sidebar = ({ onChange, isMobileView, setIsShowMobileFilter }) => {
  const [allcheck, setCheckall] = useState(true);
  const { searchText } = useSelector(({ search }) => search);
  const [mydealCheck, setmydealCheck] = useState(false);
  const [dealoftCheck, setdealoftCheck] = useState(false);
  const [OptionValue,setOptionValue] = useState(0);
  const couponListsData = useSelector(({ coupon }) => coupon.couponData);
  useEffect(() => {
    onallChangeoption("0");
  }, [searchText]);
  const onChangeoption = (value) => {
    setOptionValue(value);
    onChange(value);
    setCheckall(false);
    if (value === "10002") {
      setdealoftCheck(false);
      setmydealCheck(true);
    }
    if (value === "10001") {
      setmydealCheck(false);
      setdealoftCheck(true);
    }
    setIsShowMobileFilter && setIsShowMobileFilter()
  };
  
  const onallChangeoption = (value) => {
    setOptionValue(value);
    onChange(value);
    setCheckall(true);
    setmydealCheck(false);
    setdealoftCheck(false);
    setIsShowMobileFilter && setIsShowMobileFilter()
  };

  const couponCategoriesData = useSelector(
    ({ coupon }) => coupon.couponCategoriesData
  );

  const couponCategoriesDataLoading = useSelector(
    ({ coupon }) => coupon.couponCategoriesDataLoading
  );

  return (
    <div className={`listing-sidebar-sticky-coupon-sidebar ${isMobileView ? "mobile-view" : ""}`}>
      <h2>Departments</h2>

      <div className="sidebar-item-list">
        <div className="ift-item-with-checkbox" key={1}>
          <input
            type="checkbox"
            id="all"
            checked={
              OptionValue == 0
            }
          />
          <label htmlFor={"All"} onClick={() => onallChangeoption("0")}>
            <span>{"All Categories"}</span>
          </label>
        </div>
        <div className="spaceDiv"></div>

        {!couponCategoriesDataLoading && couponCategoriesData &&
          couponCategoriesData.map((option) => {
            return (
              <>
                <div
                  className="ift-item-with-checkbox"
                  key={option.product_category_id}
                >
                  <input
                      type="checkbox"
                      id={option.product_category_id}
                      checked={
                        option.product_category_id === OptionValue
                      }
                    />

                  <label
                    htmlFor={option.product_category_name
                      .split(" ")
                      .join("_")
                      .toLowerCase()}
                    onClick={() => onChangeoption(option.product_category_id)}
                  >
                    <span>{option.product_category_name}</span>
                  </label>
                </div>
                <div className="spaceDiv"></div>
              </>
            );
          })}
        {/* // onChangeoption */}
      </div>
    </div>
  );
};

export default Sidebar;
