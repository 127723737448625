import React from "react";
import CampaignProduct from "../CampaignProductListSlider/CampaignProductCard";

import '../CampaignProductListSlider/CampaignProductCard/campaign-product.scss';

const Campaigner = ({ departmentproductData,index }) => {
   return (
      <section className="campaign-product-list container" key={index}>
      {departmentproductData.map((productItem) => {
       return ( 
        <> 
          <div className="campaign-department-title" key={productItem.order_by}>
            <h1>{productItem.name}</h1>
          </div>
          <div className="campaign-card-list">
            {productItem.items.map((product) => {
              return <CampaignProduct product={product} addButtonText={"Add to Cart"} key={product.product_id}  />
            })}
          </div>
        </>
        )
      })}
      </section>
    
  );
};
export default Campaigner;
