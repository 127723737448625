import { combineReducers } from "redux";
import user from "./user";
import common from "./common";
import store from "./store";
import homepage from "./homepage";
import cartWishlist from "./cartWishlist";
import product from "./product";
import hotOffers from "./hotOffers";
import aboutus from "./aboutus";
import reward from "./reward";
import search from "./search";
import weeklyad from "./weeklyad";
import weeklyadgallery from "./weeklyadgallery";
import contactus from "./contactus";
import feedback from "./feedback";
import couponsDepartment from "./couponsDepartment";
import checkout from "./checkout";
import customlist from "./customlist";
import mobileApp from "./mobileApp";
import coupon from "./coupon";
import cartCoupon from "./cartCoupon";
import footer from "./footer";
import featureProduct from "./featureProduct";
import career from "./career";
import employment from "./employment";
import customer from "./customer";
import blog from "./blog";

import donation from "./donation";
import campaign from "./campaign";
import adsection from "./adsection";
import storehistory from "./storehistory";
import cateringAndMeal from "./cateringAndMeal";
import recipes from './recipes';
import analytics from './analytics';
import customPage from './customPage';

const appReducer = combineReducers({
  user,
  common,
  store,
  homepage,
  cartWishlist,
  product,
  hotOffers,
  aboutus,
  reward,
  search,
  weeklyad,
  weeklyadgallery,
  contactus,
  feedback,
  couponsDepartment,
  checkout,
  customlist,
  mobileApp,
  coupon,
  cartCoupon,
  footer,
  featureProduct,
  career,
  employment,
  customer,
  donation,
  blog,
  campaign,
  adsection,
  storehistory,
  cateringAndMeal,
  recipes,
  analytics,
  customPage
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
