import React, { useState, useEffect } from "react";
import Slider from "react-slick/lib/slider";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import SliderDots from "./SliderDots";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./weekly-single-ads.scss";

import { getWeeklyadgallery } from "redux/actions";

import AdsSpaceSection from "./AdsSpaceSection";
import WeeklyAdSpaceSectionRightSide from "./WeeklyAdSpaceSectionRightSide";
import { getLocalStorageValue } from 'config/helper';

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div
      className="cus-slider-arrow-design right-arrow-align"
      onClick={onClick}
    >
      <i className="fa-solid fa-right-long"></i>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="cus-slider-arrow-design left-arrow-align" onClick={onClick}>
      <i className="fa-solid fa-left-long"></i>
    </div>
  );
};

const WeeklySingleAds = () => {
  const { storeid, storename } = useParams();
  const clientId = getLocalStorageValue("RSAclient-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const dispatch = useDispatch();
  const [activeDots, setActiveDots] = useState(1);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeid,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getWeeklyadgallery(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-line
  const { weeklyadgalleryData } = useSelector(
    ({ weeklyadgallery }) => weeklyadgallery
  );

  const {
    ad_start_date = "",
    ad_end_date = "",
    ads_section = {},
    coupons_section = [],
  } = weeklyadgalleryData;

  const dots = Array.from(
    {
      length:
        weeklyadgalleryData?.data?.length > 0
          ? weeklyadgalleryData?.data?.length
          : 10,
    },
    (_, i) => i + 1
  );

  const handleActiveDot = (dot) => {
    setActiveDots(dot);
  };
  const onPrev = () => {
    setActiveDots(activeDots - 1);
  };
  const onNext = () => {
    setActiveDots(activeDots + 1);
  };

  const weeklygallerySingleData = weeklyadgalleryData?.data?.find(
    (galleryData) => galleryData.page_number === activeDots
  );

  const breadcrumbList = [
    { lable: "Weekly Ads", link: "/weekly-ads", searchTag: "" },
    { lable: storename, link: "", searchTag: "" },
  ];

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Weekly Ads" />

      <div className="weekly-ads-inner-section">
        <div className="container">
          <div className="weekly-ads-wrapper">
            <AdsSpaceSection adImage={ads_section} />

            <div className="weekly-ads-wrapper-inner">
              <div className="weekly-add-inner-slider">
                <div>
                  <SliderDots
                    pages={dots.length}
                    activeSlide={activeDots}
                    handleActiveDot={handleActiveDot}
                    onPrev={onPrev}
                    onNext={onNext}
                  />
                  {/* {ad_start_date && ad_end_date ? (
                    <h3 className="valid-weekly-ad-item-price">
                      Prices valid from {ad_start_date} - {ad_end_date}
                    </h3>
                  ) : (
                    ""
                  )} */}
                  <Slider {...settings}>
                    {weeklyadgalleryData?.data?.length > 0 ? (
                      <div className="ads-image-style" key={"1"}>
                        <img
                          src={weeklygallerySingleData.url}
                          alt="AdsBanner"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Slider>
                  <SliderDots
                    pages={dots.length}
                    activeSlide={activeDots}
                    handleActiveDot={handleActiveDot}
                    onPrev={onPrev}
                    onNext={onNext}
                  />
                </div>
              </div>

              <WeeklyAdSpaceSectionRightSide couponList={coupons_section} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WeeklySingleAds;
