import {
  FETCH_REWARD_DATA,
  FETCH_REWARD_DATA_SUCCESS,
  FETCH_REWARD_DATA_FAILURE,
  FETCH_REDEEM_LIST,
  FETCH_REDEEM_LIST_SUCCESS,
  FETCH_REDEEM_LIST_FAILURE,
  SET_REDEEM_OPTION,
  SET_REDEEM_OPTION_SUCCESS,
  SET_REDEEM_OPTION_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetch about data
export const fetchRewardDetails = (payload) => {
  return {
    type: FETCH_REWARD_DATA,
    payload: payload,
  };
};
export const fetchRewardDetailsSuccess = (payload) => {
  return {
    type: FETCH_REWARD_DATA_SUCCESS,
    payload: payload,
  };
};
export const fetchRewardDetailsFailure = (payload) => {
  return {
    type: FETCH_REWARD_DATA_FAILURE,
    payload: payload,
  };
};

export const getRewardDetails = (body) => async (dispatch) => {
  dispatch(fetchRewardDetails());

  axios
    .post("/AogGetRewards", body)
    .then((res) => {
      dispatch(fetchRewardDetailsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchRewardDetailsFailure({ error: error.data.message }));
    });
};

export const fetchRedeemList = (payload) => {
  return {
    type: FETCH_REDEEM_LIST,
    payload: payload,
  };
};
export const fetchRedeemListSuccess = (payload) => {
  return {
    type: FETCH_REDEEM_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchRedeemListFailure = (payload) => {
  return {
    type: FETCH_REDEEM_LIST_FAILURE,
    payload: payload,
  };
};

export const getRedeemListDetails = (body) => async (dispatch) => {
  dispatch(fetchRedeemList());
  axios
    .post("/AogGetRedeemList", body)
    .then((res) => {
      dispatch(fetchRedeemListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchRedeemListFailure({ error: error.data.message }));
    });
};

export const setRedeemOption = (payload) => {
  return {
    type: SET_REDEEM_OPTION,
    payload: payload,
  };
};
export const setRedeemOptionSuccess = (payload) => {
  return {
    type: SET_REDEEM_OPTION_SUCCESS,
    payload: payload,
  };
};
export const setRedeemOptionFailure = (payload) => {
  return {
    type: SET_REDEEM_OPTION_FAILURE,
    payload: payload,
  };
};

export const setSelectedRedeemOption = (body) => async (dispatch) => {
  dispatch(setRedeemOption());
  axios
    .post("/AogGetRedeemApply", body)
    .then((res) => {
      dispatch(setRedeemOptionSuccess(res.data));
    })
    .catch((error) => {
      dispatch(setRedeemOptionFailure({ error: error.data.message }));
    });
};
