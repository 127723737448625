import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ breadcrumbList }) => {
  return (
    <div className="breadcrumb">
      <div className="container">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          {breadcrumbList.map((breadcrumb, index) => {
            return (
              <React.Fragment key={index}>
                <li>
                  <i className="fa-solid fa-chevron-right"></i>
                </li>
                <li>
                  <Link to={`${breadcrumb.link}`}> {breadcrumb.lable} </Link>
                </li>
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Breadcrumb;
