import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import ProductList from "components/ProductList";
import HotOffers from "components/HotOffers";
import ProductListingPageSidebar from "components/ProductListingPage/Sidebar";
import ProductListingPageAction from "components/ProductListingPage/Actions";
import Loader from "components/Loader";
import {
  getHotOffersData,
  getFilterOptionList,
  getProductListByDepartment,
  getDepartmentTree,
  getSearchDepartmentTree,
  updateActivePage
} from "redux/actions";
import "./products.scss";
import usePrevious from "hooks/userPrevious";
import { getLocalStorageValue } from 'config/helper';


const Products = () => {
  const dispatch = useDispatch();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const {
    activePageNo,
    sortByValue,
    iftCheckedList,
    filterOptionList,
    minPriceValue,
    maxPriceValue,
    departmentTree,
    searchDepartmentTree,
    nodeFunctionalText,
    nodeFunctionalLevel,
  } = useSelector(({ product }) => product);

  const sortByOptionList = filterOptionList.sort_by_section;
  const selectedSortByOption =
    sortByOptionList &&
    sortByOptionList.find((option) => option.sort_id === sortByValue);

  const sortBy = selectedSortByOption && selectedSortByOption.field_name;

  const { searchText } = useSelector(({ search }) => search);
  const prevSearch = usePrevious(searchText);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const productListByDepartmentTotalCount = useSelector(
    ({ product }) => product.productListByDepartmentTotalCount
  );

  const {
    productListByDepartment: productList,
    productListByDepartmentLoading,
  } = useSelector(({ product }) => product);

  const [pageLimit, setPageLimit] = useState("");

  const breadcrumbList = [{ lable: "Shop", link: "", searchTag: "" }];

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getFilterOptionList(body));
    dispatch(getHotOffersData(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  const iftCheckedListJoin = iftCheckedList.join("|");

  useEffect(() => {
    if (prevSearch !== searchText) {
      dispatch(updateActivePage(1));
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: prevSearch !== searchText ? 1 : activePageNo,
      search: searchText,
      category_ids: "",
      ift: iftCheckedListJoin,
      limit: pageLimit,
      sort_by: sortBy || "",
      min_price: minPriceValue.toString(),
      max_price: maxPriceValue.toString(),
      funtional_text: nodeFunctionalText,
      funtional_level: nodeFunctionalLevel,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getProductListByDepartment(body));
  }, [
    dispatch,
    iftCheckedListJoin,
    sortBy,
    pageLimit,
    activePageNo,
    minPriceValue,
    maxPriceValue,
    nodeFunctionalText,
    nodeFunctionalLevel,
    searchText,
    storeId,
    clientId,
    memberNumber,
    userToken,
    isUserLoggedIn,
    prevSearch
  ]); // eslint-disable-line



  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: searchText,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getSearchDepartmentTree(body));
  }, [searchText, storeId, isUserLoggedIn]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    !departmentTree.length && dispatch(getDepartmentTree(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  const hotOffersData = useSelector(({ hotOffers }) => hotOffers.hotOffersData);
  const hotOfferTitle = hotOffersData.title;
  const adverstiseSectionData = hotOffersData.adverstise_section;

  // For sort by filter

  const sortByOption =
    sortByOptionList &&
    sortByOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.sort_id,
        };
      });

  // For show per page

  const listLimitOptionList = filterOptionList.list_limit_section;

  const defaultDropdownValue =
    listLimitOptionList &&
    listLimitOptionList.find((option) => option.is_default === "1");

  const { title = "100" } = defaultDropdownValue || {};

  useEffect(() => {
    defaultDropdownValue && setPageLimit(title);
  }, [title]); // eslint-disable-line

  const listLimitOption =
    listLimitOptionList &&
    listLimitOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.title,
        };
      });

  const productListByDepartmentTotalPages = useSelector(
    ({ product }) => product.productListByDepartmentTotalPages
  );

  const filterOptions = useSelector(
    ({ product }) => product.filterOptions
  );

  const priceSection = useSelector(
    ({ product }) => product.filterOptionList.price_section
  );

  const updatedDepartmentTree =
    searchDepartmentTree &&
    searchDepartmentTree.map((department) => {
      return {
        ...department,
        text: department.name,
      };
    });

  const updatedOtherDepartmentTree =
    departmentTree &&
    departmentTree.filter((department) => {
      const categoryIds = searchDepartmentTree.map(
        (searchDepartment) => searchDepartment.category_ids
      );
      return !categoryIds.includes(department.category_ids);
    });


  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Shop" />
      <div className="container">
        <div className="listing-page-wrapper">
          <div className="listing-page-wrapper-items">
            <ProductListingPageSidebar
              departmentTree={updatedDepartmentTree}
              priceSection={priceSection}
              otherDepartmentTree={updatedOtherDepartmentTree}
              iftOptionList={filterOptions}
              maxPriceValue={maxPriceValue}
              minPriceValue={minPriceValue}
              redirectBaseUrl="departments"
            />
          </div>
          <div className="listing-page-wrapper-items">
            <ProductListingPageAction
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              listLimitOption={listLimitOption}
              sortByOption={sortByOption}
              productListByDepartmentTotalCount={
                productListByDepartmentTotalCount
              }
              redirectBaseUrl="departments"
            />

            {productListByDepartmentLoading ? (
              <Loader />
            ) : (
              <ProductList
                activePageNo={activePageNo}
                productList={productList}
                productListByDepartmentTotalPages={
                  productListByDepartmentTotalPages
                }
              />
            )}
          </div>
        </div>

        <HotOffers
          title={hotOfferTitle}
          adverstiseSectionData={adverstiseSectionData}
        />
      </div>
    </>
  );
};
export default Products;
