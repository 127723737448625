import React, { useEffect } from "react";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useDispatch, useSelector } from "react-redux";
import AdsSpaceSection from "./AdsSpaceSection";
import { getCampaignproduct } from "redux/actions";
import { useParams } from "react-router-dom";
import { getLocalStorageValue } from 'config/helper';
import Campaigner from "./Campaigner";
import loader from "assets/images/loader.gif";


const Campaign = () => {
  const dispatch = useDispatch();
  const { campaign_slug } = useParams();

  const clientId = getLocalStorageValue("RSAclient-id");

  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");

  const campaignData = useSelector(({ campaign }) => campaign.campaignproductData);
  const adsDetails = useSelector(({ campaign }) => campaign.campaignproductData);
  const campaignproductDataLoading = useSelector(({ campaign }) => campaign.campaignproductDataLoading);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      cammpagin_slug: campaign_slug,
      user_token: isUserLoggedIn ? userToken : ""
    };
    dispatch(getCampaignproduct(body));
  }, [dispatch, campaign_slug]); // eslint-disable-line

  const breadcrumbList = [
    { lable: "Campaign", link: "", searchTag: "" },
    { lable: Object.keys(campaignData).length > 0 ? campaignData.campagin_data.campaign_name : '', link: "", searchTag: "" },
  ];



  return (
    <>
     {campaignproductDataLoading && (
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <img style={{ width: '100px', height: '50px' }} src={loader} />
      <span>Loading, please wait....</span>
    </div>
     )}
      {!campaignproductDataLoading && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title={campaignData.campagin_data.campaign_name} //Need to pass title through api res
          />
          <AdsSpaceSection adsDetails={adsDetails && adsDetails} />
          <Campaigner departmentproductData={campaignData.dep_product_list}/>
        </>
      )}
    </>
  );
};
export default Campaign;
