import React from "react";
import { Link } from "react-router-dom";
import "./image-card.scss";

const ImageCard = ({ image, clientStoreName, clientStoreId }) => {
  return (
    <div className="image-card-wrapper">
      <Link to={`/weekly-ads/${clientStoreId}/${clientStoreName}`}>
        <img src={image.url} alt=""></img>
      </Link>
    </div>
  );
};

export default ImageCard;
