import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductPreviewSlider from "./ProductPreviewSlider";
// import AddToMyList from "components/AddToMyList";
import {
  addToCartCateringAndMealKitItem,
  getRemoveProductToCartList,
  resetSelectedCustomOption,
  resetAddCateringAndMealKitProductToCartError
} from "redux/actions";
import { useNavigate } from "react-router-dom";
import "./catering-product-preview.scss";
import CateringDropdown from "../CateringDropdown";
import CateringCheckBoxGroup from "../CateringCheckBoxGroup"
import CateringRadioGroup from "../CateringRadioGroup"
import { getLocalStorageValue } from 'config/helper';

const CateringProductPreview = ({ productDetails }) => {
  const {
    name,
    image,
    custom_options,
    sku,
    price1,
    mark_as_age_restricted_department_message,
    gallery,
    product_id,
    final_price,
    categories: { department_name = "" },
    stores_inventory: { has_coupon = "0", digital_coupon_amount = "0" },
  } = productDetails;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const {
    cateringAndMealKitProductCartList,
    cateringAndMealKitProductAddLoading,
    wishListItemsStatus,
    wishListItemsMessage,
    cateringAndMealKitProductAddError
  } = useSelector(({ cartWishlist }) => cartWishlist);

  const { selectedCateringAndMealOption } = useSelector(
    ({ cateringAndMeal }) => cateringAndMeal
  );

  useEffect(() => {
    dispatch(resetSelectedCustomOption())
    dispatch(resetAddCateringAndMealKitProductToCartError())
  }, []) // eslint-disable-line

  const addedProcduct =
    cateringAndMealKitProductCartList &&
    cateringAndMealKitProductCartList.find((product) => product.product_id === product_id);

  const itemId = addedProcduct && addedProcduct.item_id;
  const cartProductQuantity = addedProcduct && addedProcduct.qty;

  const requiredOptions = custom_options ? custom_options.filter(option => option.is_required === "1") : []
  const requiredOptionsIds = requiredOptions.map(option => option.id)
  const selectedOptionList = Object.values(selectedCateringAndMealOption).flat()
  const selectedOptionListIds = selectedOptionList.map(option => option.option_id)
  const isDisabledAddToCart = !requiredOptionsIds.every(requiredOptionsId => {
    return selectedOptionListIds.includes(requiredOptionsId)
  })
  const optionItemList = selectedOptionList.filter(option => option.is_add_option_price === "1")
  const displayOptionItemList = selectedOptionList.filter(option => option.is_add_option_price === "0")


  const [productQuantity, setProductQuantity] = useState(
    +cartProductQuantity || 1
  );
  //const [likeButtonToggle, setLikeButtonToggle] = useState(false);

  const [isAddingProductToCart, setAddingProductToCart] = useState(false);


  const handleIncrementCount = () => {
    setProductQuantity(productQuantity + 1);
    ;
  };
  const handleDecrementCount = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);

    } else {
      addedProcduct && handleRemoveProductToCart(itemId);
      setProductQuantity(1);
    }
  };

  // const handleLikeButtonToggle = () => {
  //   setLikeButtonToggle(!likeButtonToggle);
  // };

  // const handleAddToWishlist = (list_id) => {
  //   if (!isUserLoggedIn) {
  //     navigate("/login");
  //   }
  //   const body = {
  //     RSAClientId: clientId,
  //     ClientStoreId: +storeId || 1,
  //     product_id: product_id,
  //     AppName: isUserLoggedIn ? "shop" : "",
  //     member_number: isUserLoggedIn ? memberNumber : "",
  //     user_token: isUserLoggedIn ? userToken : "",
  //     Version: isUserLoggedIn ? "1" : "",
  //     DeviceType: isUserLoggedIn ? "web" : "",
  //     qty: productQuantity,
  //     list_id,
  //   };

  //   isUserLoggedIn &&
  //     dispatch(addToWishlist(body)).then(() => {
  //       setShowMyListDropdown(false);
  //     });
  // };

  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    const optionPrice = optionItemList.reduce((acc, cur) => {
      return acc += +cur.price
    }, 0)
    const displayOptionPrice = displayOptionItemList.reduce((acc, cur) => {
      return acc += +cur.price
    }, 0)

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      qty: productQuantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      product_note: "",
      custom_options_arr: JSON.stringify(selectedOptionList) || "",
      option_price: optionPrice || "0",
      display_option_item_price: displayOptionPrice || "",
    };


    setAddingProductToCart(true);
    isUserLoggedIn &&
      dispatch(addToCartCateringAndMealKitItem(body)).then(() => {
        setAddingProductToCart(false);
      });
  };

  const handleRemoveProductToCart = (item_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      item_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getRemoveProductToCartList(body));
  };


  return (
    <div className="catering-product-preview-section">
      <div className="container">
        <div className="grid">
          <div className="grid-items">
            <div>
              <ProductPreviewSlider image={image} gallery={gallery} />
            </div>
          </div>
          <div className="grid-items">
            <h1>{name}</h1>
            {/*<div className="rating-alignment">
              <div className="star-alignment">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
             </div>
              <div className="review-text">
                <span>(32 reviews)</span>
              </div>
            </div>*/}
            <div className="final-price">
              <div className="price">
                <h2>{price1}</h2>
              </div>
              {/*<div className="discount-price">
          <p>20% off</p>
          <span>
            <del>$8.30</del>
          </span>
        </div>*/}
            </div>
            <div className="coupon-pdp-used-time-wrapper">
              {+has_coupon > 0 ? (
                <div className="coupon-design">
                  <p>
                    Digital Coupon:
                    <span> {`$ ${digital_coupon_amount}`}</span>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="department-code">
              <div>
                <p>
                  Department: <span>{department_name}</span>
                </p>
              </div>
              <div>
                <p>
                  UPC: <span>{sku}</span>
                </p>
              </div>
            </div>
            {/* <div className="other-price-details">
              {price2 !== "" ? <p>{price2}</p> : ""}
              {price3 !== "" ? <p>{price3}</p> : ""}
              {price4 !== "" ? <p>{price4}</p> : ""}
            </div> */}

            {/* {!!coupon_used_time &&
              coupon_used_time !== "1" &&
              +has_coupon > 0 ? (
                <div className="coupon-used-time-ribbon coupon-used-pdp-item">
                  <div className="coupon-used-time">
                    <p>
                      <span>Use {coupon_used_time}X</span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )} */}

            {final_price !== "0" ? (
              <div className="final-price">
                <h2> Final price: ${final_price}</h2>
              </div>
            ) : (
              ""
            )}
            {custom_options &&
              custom_options.map((option) => {
                return (
                  <div key={option.option_id}>
                    {option.type === "dropdown" ? (
                      <div className="substitution" >
                        <p><strong>{option.title}</strong>{option.is_required === "1" && <span>*</span>}</p>
                        <div className="select-substitition-dropdown">
                          <CateringDropdown
                            optionList={
                              option.values
                                ? option.values.map((list) => {
                                  return { title: list.value, value: list.id, option: list };
                                })
                                : []
                            }
                            lable=""
                            placeholder="Select Option"
                            defaultValue=""
                            groupData={option}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {option.type === "radio" ? (
                      <CateringRadioGroup groupData={option} />
                    ) : (
                      ""
                    )}
                    {option.type === "checkbox" ? (

                      <CateringCheckBoxGroup groupData={option} />
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}

            {isDisabledAddToCart && <div className="add-product-option">
              <div className="restricted">
                <p>*Required Field</p>
              </div>
            </div>}

            <div className="add-product-option">
              <div className="couter-button">
                <button disabled>
                  <span>
                    {(addedProcduct && addedProcduct.qty) || productQuantity}
                  </span>
                  <div className="arrow-right-side">
                    <div
                      onClick={handleIncrementCount}
                      title="Click to increase the qty"
                    >
                      <i className="fa-solid fa-angle-up"></i>
                    </div>
                    <div
                      onClick={handleDecrementCount}
                      title="Click to decrease the qty"
                    >
                      <i className="fa-solid fa-angle-down"></i>
                    </div>
                  </div>
                </button>
              </div>
              <div className="add-to-cart">
                <button onClick={handleAddToCart} disabled={isDisabledAddToCart}>
                  <i className="fa-solid fa-cart-shopping"></i>
                  <span>
                    {cateringAndMealKitProductAddLoading && isAddingProductToCart
                      ? "Adding..."
                      : "Add"}
                  </span>
                </button>
              </div>
              {/* <div className="link-button">
                {isUserLoggedIn ? (
                  <AddToMyList
                    isShowMyListDropdown={isShowMyListDropdown}
                    isShowNewListModal={isShowNewListModal}
                    setShowNewListModal={setShowNewListModal}
                    setShowMyListDropdown={setShowMyListDropdown}
                    handleApply={handleAddToWishlist}
                    loading={wishListItemsLoading}
                    title="Add to my list"
                  />
                ) : (
                  ""
                )}
              </div> */}
              {wishListItemsStatus && wishListItemsStatus === "error"
                ? wishListItemsMessage
                : ""}
            </div>
            {cateringAndMealKitProductAddError && (
              <div className="restricted">
                <p>{cateringAndMealKitProductAddError}</p>
              </div>
            )}
            {mark_as_age_restricted_department_message && (
              <div className="restricted">
                <p>{mark_as_age_restricted_department_message}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CateringProductPreview;
