import {
  FETCH_COMMON_DETAILS,
  FETCH_COMMON_DETAILS_SUCCESS,
  FETCH_COMMON_DETAILS_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";

// fetach common data
export const fetchCommonDetails = (payload) => {
  return {
    type: FETCH_COMMON_DETAILS,
    payload: payload,
  };
};
export const fetchCommonDetailsSuccess = (payload) => {
  return {
    type: FETCH_COMMON_DETAILS_SUCCESS,
    payload: payload,
  };
};
export const fetchCommonDetailsFailure = (payload) => {
  return {
    type: FETCH_COMMON_DETAILS_FAILURE,
    payload: payload,
  };
};

export const getCommonDetails = (body) => async (dispatch) => {
  dispatch(fetchCommonDetails());

  axios
    .post("/AogGetCommonDetails", body)
    .then((res) => {
      dispatch(fetchCommonDetailsSuccess(res.data.data));
    })
    .catch((error) => {

      dispatch(fetchCommonDetailsFailure({ error: error.data.message }));
    });
};
