import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerAddressList, selectCustomerAddress } from "redux/actions";
import "./address-info.scss";
import { getLocalStorageValue } from 'config/helper';

const AddressInfo = ({ handleAddNewAddress }) => {
  const dispatch = useDispatch();
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const [selectedAddress, setSelectedAddress] = useState("");

  const { customerAddressList, checkoutStoreType } = useSelector(
    ({ checkout }) => checkout
  );
  const { storeListData } = useSelector(({ store }) => store);

  const defaultSelectedAddress =
    customerAddressList &&
    customerAddressList.find((address) => address.is_primary === "1");

  const selectedStore =
    storeListData &&
    storeListData.find(
      (store) =>
        store.ClientStoreId === getLocalStorageValue("selected-store-id")
    );

  const {
    AddressLine1 = "",
    AddressLine2 = "",
    City = "",
    ZipCode = "",
    StateName = "",
  } = selectedStore || {};

  useEffect(() => {
    if(defaultSelectedAddress !== undefined)
    {
      setSelectedAddress(defaultSelectedAddress.address_id);
      dispatch(selectCustomerAddress(defaultSelectedAddress.address_id));
    }
    else
    {
      dispatch(selectCustomerAddress(""));
    }
  }, [dispatch, defaultSelectedAddress]);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: memberNumber,
      user_token: userToken,
      DeviceType: "web",
      Version: "1",
      AppName: "shop",
    };
    customerAddressList &&
      !customerAddressList.length &&
      dispatch(getCustomerAddressList(body));
  }, [memberNumber, userToken, storeId]); // eslint-disable-line

  const handleAddressChange = (e) => {
    const { value } = e.target;
    setSelectedAddress(value);
    dispatch(selectCustomerAddress(value));
  };
  
  return (
    <div className="address-info-wrapper">
      {checkoutStoreType === "store_pickup" ? (
        selectedStore ? (
          <div className="address-info-section">
            <div>
              <h1>Your pickup location</h1>
            </div>

            <div className="radio-alignment">
              <div>
                <p>
                  {AddressLine1} {AddressLine2 ? AddressLine2 : ""},<br></br>
                  {City}, {StateName}, {ZipCode}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )
      ) : (
        <div className="address-info-section">
          <div>
            <h1>Choose your address</h1>

            <button
              onClick={handleAddNewAddress}
              className="primary-outlined-button"
            >
              Add new address
            </button>
          </div>

          {customerAddressList &&
            customerAddressList.map((list) => {
              const {
                first_name,
                last_name,
                address_1,
                address_2,
                city,
                state,
                zip_code,
                address_id,
              } = list;
              return (
                <div className="radio-alignment" key={address_id}>
                  <div>
                    <input
                      type="radio"
                      id={`AddressCard${address_id}`}
                      name="address"
                      value={address_id}
                      onChange={handleAddressChange}
                      checked={selectedAddress === address_id}
                    />
                    <label htmlFor={`AddressCard${address_id}`}>&nbsp;</label>
                    <p>
                      {first_name} {last_name} <br></br>
                      {address_1} {address_2 ? address_2 : ""},<br></br>
                      {city}, {state}, {zip_code}
                    </p>
                  </div>
                </div>
              );
            })}
          {selectedAddress === ""  &&  (
            <span className="contact-helper-text" >
              *Please choose address for delivery
            </span>
          )}
        </div>
      )}
    </div>
  );
};
export default AddressInfo;
