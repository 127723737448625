import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectCheckoutPaymentMethod } from "redux/actions";
import "./payment-method.scss";
import { useParams } from "react-router-dom";

const PaymentMethod = () => {
  const dispatch = useDispatch();
  const { checkoutType } = useParams();
  const [paymentMethod, setPaymentMethod] = useState();

  const { storeDetails } = useSelector(({ store }) => store);
  const { checkoutStoreType, ebtPaymentPaidList } = useSelector(
    ({ checkout }) => checkout
  );

  const { cateringAndMealKitProductCartList, productCartList } = useSelector(
    ({ cartWishlist }) => cartWishlist
  );

  const isEbtProductAvailable = productCartList.some(
    (product) => product.ebt_item === "1"
  );

  const {
    pickup_cc = "0",
    pickup_cod = "0",
    delivery_cc = "0",
    delivery_cod = "0",
    catering_pickup_cc = "0",
    catering_pickup_cod = "0",
    catering_delivery_cc = "0",
    catering_delivery_cod = "0",
    allow_delivery_default_payment_method = "",
    allow_pickup_default_payment_method = "",
    allow_catering_pickup_default_payment_method = "0",
    allow_catering_delivery_default_payment_method = "0",
    allow_pickup_ebt = "0",
    allow_delivery_ebt = "0",
  } = storeDetails || {};

  const { is_mealkit = "0" } = cateringAndMealKitProductCartList[0] || {};

  useEffect(() => {
    //grocery
    if (!checkoutType) {
      
      if (checkoutStoreType === "store_pickup") {
        var pickupDefaultPaymentMethod = (isEbtProductAvailable && ebtPaymentPaidList.length === 0) ? "ebt" : allow_pickup_default_payment_method;
        setPaymentMethod(pickupDefaultPaymentMethod);
        dispatch(
          selectCheckoutPaymentMethod(pickupDefaultPaymentMethod)
        );

        // if (pickup_cc === "1") {
        //   setPaymentMethod("cc");
        //   dispatch(selectCheckoutPaymentMethod("cc"));
        // }
        // if (pickup_cod === "1") {
        //   setPaymentMethod("cod");
        //   dispatch(selectCheckoutPaymentMethod("cod"));
        // }
      }
      if (checkoutStoreType === "delivery") {
        var deliveryDefaultPaymentMethod = (isEbtProductAvailable && ebtPaymentPaidList.length === 0) ? "ebt" : allow_delivery_default_payment_method;
        setPaymentMethod(deliveryDefaultPaymentMethod);
        dispatch(
          selectCheckoutPaymentMethod(deliveryDefaultPaymentMethod)
        );

        // if (delivery_cc === "1") {
        //   setPaymentMethod("cc");
        //   dispatch(selectCheckoutPaymentMethod("cc"));
        // }
        // if (delivery_cod === "1") {
        //   setPaymentMethod("cod");
        //   dispatch(selectCheckoutPaymentMethod("cod"));
        // }
      }
    }

    //mealkit
    if (checkoutType === "catering-meal" && is_mealkit === "1") {
      setPaymentMethod("cc");
      dispatch(selectCheckoutPaymentMethod("cc"));
    }

    //catering
    if (checkoutType === "catering-meal" && is_mealkit === "2") {
      if (checkoutStoreType === "store_pickup") {
        setPaymentMethod(allow_catering_pickup_default_payment_method);
        dispatch(
          selectCheckoutPaymentMethod(
            allow_catering_pickup_default_payment_method
          )
        );
      } else {
        setPaymentMethod(allow_catering_delivery_default_payment_method);
        dispatch(
          selectCheckoutPaymentMethod(
            allow_catering_delivery_default_payment_method
          )
        );
      }
    }
  }, [checkoutStoreType, storeDetails]); // eslint-disable-line

  const handleChange = (e) => {
    const { value } = e.target;
    setPaymentMethod(value);
    dispatch(selectCheckoutPaymentMethod(value));
  };
  return (
    <div className="payment-method-section">
      <h1>
        <strong>Choose payment method</strong>
      </h1>
      {isEbtProductAvailable && ebtPaymentPaidList.length === 0 && (
        <div className="radio-alignment">
          {/* grocery */}
          {!checkoutType &&
            (allow_delivery_ebt === "1" || allow_pickup_ebt === "1") && (
              <div>
                <input
                  type="radio"
                  value="ebt"
                  id="ebt"
                  name="paymentMethod"
                  onChange={handleChange}
                  checked={paymentMethod === "ebt"}
                />
                <label htmlFor="ebt">Pay with EBT SNAP</label>
              </div>
            )}
        </div>
      )}
      {/* grocery */}
      {!checkoutType && (
        <div className="radio-alignment">
          {((checkoutStoreType === "store_pickup" && pickup_cc === "1") ||
            (checkoutStoreType === "delivery" && delivery_cc === "1")) && (
            <div>
              <input
                type="radio"
                value="cc"
                id="credit-card"
                name="paymentMethod"
                onChange={handleChange}
                checked={paymentMethod === "cc"}
              />
              <label htmlFor="credit-card">Credit Card</label>
            </div>
          )}
        </div>
      )}

      {/* grocery */}
      {!checkoutType && ebtPaymentPaidList.length === 0 && (
        <div className="radio-alignment">
          {/* Checkout Page COD Label added Dynamically - Card Num : 1634 - 07/02/2023  - Madhu */}
          {checkoutStoreType === "store_pickup" && pickup_cod === "1" ? (
            <div>
              <input
                type="radio"
                value="cod"
                id="COD"
                name="paymentMethod"
                onChange={handleChange}
                checked={paymentMethod === "cod"}
              />
              <label htmlFor="COD">{storeDetails.pickup_cod_lable}</label>
            </div>
          ) : checkoutStoreType === "delivery" && delivery_cod === "1" ? (
            <div>
              <input
                type="radio"
                value="cod"
                id="COD"
                name="paymentMethod"
                onChange={handleChange}
                checked={paymentMethod === "cod"}
              />
              <label htmlFor="COD">{storeDetails.delivery_cod_lable}</label>
            </div>
          ) : (
            // disabled COD - Madhu -  16/02/2023 - Card Number : 1717
            ""
          )}
        </div>
      )}
      {/* mealkit */}
      {checkoutType === "catering-meal" && is_mealkit === "1" && (
        <div className="radio-alignment">
          <div>
            <input
              type="radio"
              value="cc"
              id="credit-card"
              name="paymentMethod"
              onChange={handleChange}
              checked={paymentMethod === "cc"}
            />
            <label htmlFor="credit-card">Credit Card</label>
          </div>
        </div>
      )}

      {/* catering pickup*/}
      {checkoutType === "catering-meal" &&
        is_mealkit === "2" &&
        checkoutStoreType === "store_pickup" && (
          <div className="radio-alignment">
            {catering_pickup_cc === "1" && (
              <div>
                <input
                  type="radio"
                  value="cc"
                  id="credit-card"
                  name="paymentMethod"
                  onChange={handleChange}
                  checked={paymentMethod === "cc"}
                />
                <label htmlFor="credit-card">Credit Card</label>
              </div>
            )}
          </div>
        )}
      {checkoutType === "catering-meal" &&
        is_mealkit === "2" &&
        checkoutStoreType === "store_pickup" && (
          <div className="radio-alignment">
            {catering_pickup_cod === "1" && (
              <div>
                <input
                  type="radio"
                  value="cod"
                  id="COD"
                  name="paymentMethod"
                  onChange={handleChange}
                  checked={paymentMethod === "cod"}
                />
                <label htmlFor="COD">COD</label>
              </div>
            )}
          </div>
        )}

      {/* catering delivery*/}
      {checkoutType === "catering-meal" &&
        is_mealkit === "2" &&
        checkoutStoreType === "delivery" && (
          <div className="radio-alignment">
            {catering_delivery_cc === "1" && (
              <div>
                <input
                  type="radio"
                  value="cc"
                  id="credit-card"
                  name="paymentMethod"
                  onChange={handleChange}
                  checked={paymentMethod === "cc"}
                />
                <label htmlFor="credit-card">Credit Card</label>
              </div>
            )}
          </div>
        )}
      {checkoutType === "catering-meal" &&
        is_mealkit === "2" &&
        checkoutStoreType === "delivery" && (
          <div className="radio-alignment">
            {catering_delivery_cod === "1" && (
              <div>
                <input
                  type="radio"
                  value="cod"
                  id="COD"
                  name="paymentMethod"
                  onChange={handleChange}
                  checked={paymentMethod === "cod"}
                />
                <label htmlFor="COD">COD</label>
              </div>
            )}
          </div>
        )}
      {/* <div className="radio-alignment">
        <div>
          <input type="radio" id="Paypal" name="radio-group" checked />
          <label for="Paypal">Paypal</label>
        </div>
        <div>
          <span>What is PayPal?</span>
        </div>
  </div>*/}
    </div>
  );
};
export default PaymentMethod;
